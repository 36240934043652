import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { userTutorial, userTutorial2, userTutorial3 } from '@/static/images';

export const Tutorial: FunctionComponent = () => {
  return (
    <HorizontalContainer>
      <Card>
        <Row>
          <UserTutorial2 src={userTutorial2} />
        </Row>
        <Column>
          <p>Enviá la invitación</p>
          <span>Sumá a tu lista a las personas que quieras invitar o compartiles tu link.</span>
        </Column>
      </Card>
      <Card>
        <Row>
          <UserTutorial src={userTutorial} />
        </Row>
        <Column>
          <p>Esperá a que se registren</p>
          <span>Los usuarios que invites y se registren quedarán asociados a tu cuenta como referidos.</span>
        </Column>
      </Card>
      <Card>
        <Row>
          <UserTutorial3 src={userTutorial3} />
        </Row>
        <Column>
          <p>Obtené beneficios</p>
          <span>Beneficios para vos y para tus referidos.</span>
        </Column>
      </Card>
    </HorizontalContainer>
  );
};

const Row = styled.div`
  padding-right: 20px;
  ${({ theme }) => theme.breakpoint('md')} {
    padding-right: 0px;
  }
`;

const UserTutorial2 = styled.img`
  width: 100px;
`;

const UserTutorial = styled.img`
  width: 100px;
`;
const UserTutorial3 = styled.img`
  width: 60px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  > p {
    font-size: 15px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
    margin: 0px 0px 6px !important;
    ${({ theme }) => theme.breakpoint('md')} {
      text-align: center;
    }
  }
  > span {
    font-size: 12px;
    line-height: 14px;

    color: ${({ theme }) => theme.colors.grey[700]};
    ${({ theme }) => theme.breakpoint('md')} {
      text-align: center;
    }
  }
`;

const HorizontalContainer = styled.div`
  overflow-x: auto;
  /* margin-top: 30px; */
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px 0px 10px;
  ${({ theme }) => theme.breakpoint('md')} {
    margin-top: 0px;
  }
`;

const Card = styled.div`
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 420px;
  min-width: 299px;
  align-items: center;
  padding: 10px 35px 10px 25px;
  margin-right: 20px;
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
    height: 151px;
  }
`;
