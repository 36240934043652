import {
  PatternE,
  RoundedE,
  SubtitleTypeFontE,
  TitleTypeFontE,
  TypeButtonsE
} from '@/containers/StoreFrontBuilder/context/storefront';

export type ScheduleStatusT = 'bookable' | 'paused' | 'canceled' | 'finished' | 'full';

export interface UserI {
  id: number;
  auth_id: string;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  connection_type: string;
  email: string;
  phone?: string;
  dob?: string;
  avatar_url?: string;
  created_at: string;
  updated_at?: string;
  vendor?: VendorI;
  google_calendar_keys?: GoogleCalendarKeyI;
  reply_questions?: ReplyQuestionsI[];
  bookings?: BookingI[];
}

export interface NotificationI {
  id: number;
  email_messages: boolean;
  sms_messages: boolean;
  whatsapp_messages: boolean;
  email_reminders: boolean;
  sms_reminders: boolean;
  whatsapp_reminders: boolean;
}

export interface VendorClientsBaseI {
  id: number;
  user_email: string;
  agora_fee: number;
  notes?: string;
  address?: string;
  discount: number;
  vendor_id: number;
  user_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  user?: UserI;
  vendor_clients_base_history?: VendorClientsBaseHistoryI[];
}

export interface VendorClientsBaseHistoryI {
  id: number;
  title: string;
  description: string;
  vendor_id: number;
  vendor_clients_base_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  vendor_clients_base: VendorClientsBaseI;
}

export interface VendorI {
  id: number;
  plan_name?: string;
  username: string;
  address: string;
  address_details?: string;
  latitude: string;
  longitude: string;
  neighborhood: string;
  country: SupportedCountriesE;
  timezone: string;
  state: string;
  bio: string;
  professional_bio: string;
  professional_title: string;
  rating: number;
  cover_url: string;
  agora_fee: string;
  policy_refund_days: number;
  is_created_by_marketplace: boolean;
  is_published: boolean;
  is_onboarding_finished: boolean;
  is_payment_cash_enabled: boolean;
  is_payment_bank_transfer_enabled: boolean;
  status: VendorStatusE;
  vendor_working_hours_start_frequency?: number;
  payment_gateways?: WalletTypeE[];
  created_at: string;
  updated_at?: string;
  user_id?: number;
  user: UserI;
  social_media?: SocialMediaI;
  services?: ServiceI[];
  services_vendors?: ServiceVendorI[];
  subscriptions?: SubscriptionI[];
  digital_contents?: DigitalContentI[];
  certifications?: CertificationI[];
  experiences?: ExperienceI[];
  wallet_mercado_pago?: WalletMercadoPagoI;
  vendor_prompt?: VendorPromptsI;
  offline_payments?: OfflinePaymentI[];
  category_assets?: CategoryAsset[];
  vendor_links?: VendorLinkI[];
  vendor_social_apps?: VendorSocialAppI[];
  vendor_storefront?: VendorStorefrontI;
  vendor_blocked_hours?: VendorBlockedHoursI[];
  vendor_next_steps?: VendorNextStepsI[];
  unique_events?: UniqueEventI[];
  vendor_working_hours?: VendorWorkingHoursI[];
  vendor_agora_plans?: VendorAgoraPlanI[];
  vendor_status_history?: VendorStatusHistoryI[];
  vendor_permissions?: VendorPermissionsI;
  venue_id?: number;
  venue?: VenueI;
  max_booking_period?: number;
}

export interface VendorStatusHistoryI {
  id: number;
  status: VendorHistoryStatusE;
  vendor_id: number;
  created_at: Date;
  vendor: VendorI;
}

export enum VendorHistoryStatusE {
  VENUE_STAFF = 'venue_staff',
  ONBOARDING = 'onboarding',
  FREE_TRIAL = 'free_trial',
  ACTIVE_AFTER_FREE_TRIAL = 'active_after_free_trial',
  ACTIVE_AFTER_INACTIVE = 'active_after_inactive',
  INACTIVE = 'inactive',
  CHURN = 'churn'
}

export enum VendorStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted'
}

export interface VendorWorkingHoursI {
  id: number;
  day: number;
  recurrence_pattern: string;
  working_hours: WorkingHours[];
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  service_id: number;
  vendor: VendorI;
}

interface WorkingHours {
  start_time: string;
  end_time: string;
}

export interface ServiceVendorI {
  service_id: number;
  vendor_id: number;
  service: ServiceI;
  vendor: VendorI;
}

export interface VenueI {
  id: number;
  vendor_owner_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor_owner: VendorI;
  vendors?: VendorI[];
}

export interface SocialMediaI {
  id: number;
  web_link?: string | null;
  instagram_link?: string | null;
  facebook_link?: string | null;
  youtube_link?: string | null;
  linked_in_link?: string | null;
}

export interface CertificationI {
  id: number;
  title: string;
  description: string;
  order: number;
}

export interface ExperienceI {
  id: number;
  title: string;
  description: string;
  order: number;
}

export interface WalletMercadoPagoI {
  id: number;
  user_id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  address?: string;
  city?: string;
  access_token: string;
  refresh_token: string;
  public_key: string;
  expires_in: number;
  created_at: string;
  updated_at?: string;
}

export interface EventI {
  id: number;
  service_id: number;
  days: number[] | null;
  modality: ServiceModalityE;
  duration: number;
  is_recurrent: boolean;
  recurrence_pattern?: string;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  service: ServiceI;
  event_instances?: EventInstanceI[];
}

export interface EventInstanceI {
  id: number;
  address?: string;
  address_details?: string;
  latitude?: string;
  longitude?: string;
  neighborhood?: string;
  country?: SupportedCountriesE;
  state?: string;
  local_slots: number;
  virtual_slots: number;
  price: number;
  participants: number;
  modality: ServiceModalityE;
  status: ScheduleStatusE;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  event_id: number;
  event: EventI;
  virtual_meet?: VirtualMeetI;
}

export interface VirtualMeetI {
  id: number;
  meet_type: MeetTypeE;
  meet_link?: string;
  room_name?: string;
  has_started: boolean;
  started_at: Date;
  ended_at: Date;
  event_instance: EventInstanceI;
}

export interface ServiceI {
  id: number;
  vendor_id: number;
  name: string;
  description: string;
  address: string;
  latitude: string;
  longitude: string;
  neighborhood: string;
  country?: SupportedCountriesE;
  state?: string;
  is_refund_available: boolean;
  local_slots?: number;
  virtual_slots?: number;
  duration: number;
  modality: ServiceModalityE;
  meet_type?: MeetTypeE;
  meet_link?: string;
  price: number;
  price_usd?: number;
  rating: number;
  images_gallery?: string[];
  cover_url: string;
  status: ServiceStatusE;
  days: number[];
  booking_until_at_minutes: number;
  created_at: string;
  updated_at?: string;
  category_sub_category: CategorySubCategoryI;
  vendor?: VendorI;
  events?: EventI[];
  packs?: PackI[];
  address_details?: string;
  is_for_subscription: boolean;
  is_on_demand: boolean;
  policy_refund_mins: number;
  coverage_areas?: CoverageAreaI[];
  faqs?: FAQI[];
  multi_vendors: ServiceVendorI[];
  is_refund_policies_enabled: boolean;
  is_user_phone_required: boolean;
  assets_labels?: AssetLabelI[];
  down_payment?: DownPaymentI;
  custom_questions?: CustomQuestionI[];
  order: number;
  slug: string;
  buffer_before?: number;
  buffer_after?: number;
}

export interface OrderLabelsI {
  labels: {
    id: number;
    order: number;
  }[];
  services: {
    id: number;
    order: number;
    label_id: number;
  }[];
}

export interface CategoryAsset {
  id: number;
  category_id: number;
  vendor_id: number;
  service_id: number;
  category: CategoryI;
  vendor: VendorI;
  service: ServiceI;
}

export interface ServiceVendorI {
  service_id: number;
  vendor_id: number;
}

export interface CategorySubCategoryI {
  id: number;
  category: CategoryI;
  sub_category: SubCategoryI;
}

export interface ServiceSubscriptionI {
  limit: number;
  service_id: number;
  subscription_id: number;
  service: ServiceI;
  subscription?: SubscriptionI;
  is_limited: boolean;
}

export interface ServiceExceptionSubscriptionI {
  service_exception_id: number;
  subscription_id: number;
  service_exception: ServiceExceptionI;
  subscription?: SubscriptionI;
}

export interface ServiceExceptionI {
  id: number;
  name: string;
}

export interface SubCategoryI {
  id: number;
  name: string;
}

export interface CategoryI {
  id: number;
  name: string;
}

export interface PackI {
  id: number;
  service_id: number;
  limit: number;
  price: number;
  price_usd?: number;
  discount: number;
  created_at: string;
  updated_at?: string;
  service: ServiceI;
}

// Virtual Schemas

export interface VirtualSchedulesI {
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  modality: ServiceModalityE;
}

export interface VirtualEventInstancesI {
  id: string;
  modality: ServiceModalityE;
  local_slots: number;
  virtual_slots: number;
  price: number;
  participants: number;
  status: ScheduleStatusE;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  event_id: number;
  virtual_meet?: VirtualMeetI;
  address?: string;
  neighborhood?: string;
  address_details?: string;
  booking_event_instances?: BookingEventInstanceI[];
  event: Omit<EventI, 'event_instances'>;
}

export interface DownPaymentI {
  id: number;
  amount: number;
  amount_discount: number;
  is_mercado_pago_available: boolean;
  is_bank_transfer_available: boolean;
  is_cash_available: boolean;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  service_id: number;
  service: ServiceI;
  vendor: VendorI;
}

export interface EventInstanceI {
  id: number;
  local_slots: number;
  virtual_slots: number;
  price: number;
  participants: number;
  modality: ServiceModalityE;
  status: ScheduleStatusE;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  event_id: number;
  event: EventI;
  address?: string;
  neighborhood?: string;
}
export interface SubscriptionI {
  id: number;
  payment_link: string;
  vendor_id: number;
  participants: number;
  name: string;
  description: string;
  cover_url: string;
  payment_recurrence: SubscriptionRecurrenceE;
  status: SubscriptionStatusE;
  price: number;
  price_usd?: number;
  slots: number;
  created_at?: string;
  updated_at?: string;
  vendor?: VendorI;
  service_subscriptions?: ServiceSubscriptionI[];
  service_exception_subscriptions?: ServiceExceptionSubscriptionI[];
  expirationDate?: string;
  faqs?: FAQI[];
}
export interface UserWithSubscriptionI {
  id: number;
  auth_id: string;
  first_name?: string;
  last_name?: string;
  email: string;
  phone?: string;
  dob?: string;
  avatar_url?: string;
  created_at: string;
  updated_at?: string;
  subscription: SubscriptionI[];
}
export interface BookingI {
  id: number;
  type: BookingTypeE;
  status: BookingStatusE;
  rescheduled_by_user_id: number;
  rescheduled_at: Date;
  cancelled_by_user_id: number;
  cancelled_at: Date;
  user_id: number;
  vendor_id: number;
  parent_booking_id: number | null;
  created_at: string;
  updated_at?: string;
  payments?: PaymentI[];
  parent_booking?: BookingI;
  user?: UserI;
  vendor: VendorI;
  booking_event_instance?: BookingEventInstanceI;
  booking_pack?: BookingPackI;
  booking_subscription?: BookingSubscriptionI;
  booking_donation?: BookingDonationI;
  booking_unique_event?: BookingUniqueEventI;
  booking_digital_content?: BookingDigitalContentI;
  booking_notifications?: BookingNotificationI[];
  cancelled_by_user?: UserI;
  rescheduled_by_user?: UserI;
  refund?: RefundI;
  reply_questions?: ReplyQuestionsI[];
}

export interface VendorPermissionsI {
  id: number;
  agenda_view: boolean;
  agenda_edit: boolean;
  working_hours_edit: boolean;
  activity_view: boolean;
  activity_edit: boolean;
  vendor: VendorI;
}

export interface ReplyQuestionsI {
  id: number;
  reply: string;
  custom_question_id: number;
  user_id: number;
  booking_id: number;
  created_at: Date;
  updated_at?: Date;
  custom_question: CustomQuestionI;
  user: UserI;
  booking: BookingI;
}

export interface BookingUniqueEventI {
  id: number;
  unique_code: string;
  quantity: number;
  redeemed_slots: number;
  has_assisted?: boolean;
  booking_id: number;
  unique_event_id: number;
  unique_event_ticket_id: number;
  unique_event_ticket_special_id: number;
  created_at: Date;
  updated_at?: Date;
  promoter_id: number;
  booking: BookingI;
  promoter: PromoterI;
  unique_event: UniqueEventI;
  unique_event_ticket: UniqueEventTicketI;
  unique_event_ticket_special: UniqueEventTicketI;
}

export interface PromoterI {
  id: number;
  name: string;
  email: string;
  phone: string;
  commission: number;
  vendor_id: number;
  vendor: VendorI[];
}

export interface BookingDigitalContentI {
  id: number;
  booking_id: number;
  digital_content_id: number;
  created_at: Date;
  updated_at?: Date;
  booking: BookingI;
  digital_content: DigitalContentI;
}

export interface DigitalContentI {
  id: number;
  name: string;
  description: string;
  slug: string;
  cover_url: string;
  content_url: string;
  price: number;
  price_usd?: number;
  status: DigitalContentStatusE;
  images_gallery: string[];
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
  custom_questions?: CustomQuestionI[];
  faqs?: FAQI[];
  is_user_phone_required: boolean;
  is_transfer_fee_available: boolean;
  assets_labels?: AssetLabelI[];
}

export interface RefundI {
  id: number;
  type: RefundTypeE;
  status: PaymentStatusE;
  amount: number;
  created_at: string;
  updated_at?: string;
  booking_id: number;
  payment_order?: PaymentOrderI;
}

export interface PaymentOrderI {
  id: number;
  checkout_id?: string;
  payment_text: string;
  transaction_amount: number;
  currency_code: string;
  reference: string;
  source_name: string;
  source_type: string;
  card_info?: CardI;
}

export interface BookingPackI {
  id: number;
  status: BookingPackStatusE;
  start_at: Date;
  end_at: Date;
  limit: number;
  price: number;
  days: number;
  discount: number;
  service_id: number;
  booking_id: number;
  created_at: Date;
  updated_at?: Date;
  service?: ServiceI;
  ticket?: TicketI;
  booking: BookingI;
}

export interface BookingSubscriptionI {
  id: number;
  status: BookingSubscriptionStatusE;
  start_at: string;
  subscription_id: number;
  booking_id: number;
  created_at: string;
  updated_at?: string;
  subscription: SubscriptionI;
  booking?: BookingI;
  tickets?: TicketI[];
  end_at: Date;
}

export interface BookingDonationI {
  id: number;
  comment: string;
  booking_id: number;
  crowdfunding_plan_id: number;
  created_at: Date;
  updated_at?: Date;
  crowdfunding_plan?: CrowdfundingPlanI;
  booking: BookingI;
}

export interface BookingEventInstanceI {
  id: number;
  modality: ServiceModalityE;
  price: number;
  has_assisted?: boolean;
  event_instance_id: number;
  booking_id: number;
  created_at: string;
  updated_at?: string;
  event_instance: EventInstanceI;
  booking?: BookingI;
}

export interface VendorOnDemandHoursI {
  id: number;
  vendor_id: number;
  service_id?: number;
  start_at: string;
  working_hours: WorkingHours[];
  service?: ServiceI;
  vendor: VendorI;
}

export interface VendorOnDemandHoursMergedI {
  id: number;
  vendor_id: number;
  service_ids?: number[];
  start_at: string;
  working_hours: WorkingHours[];
  vendor: VendorI;
}

export interface AssetI {
  id: number;
  pack_id: number;
  subscription_id: number;
  user_id: number;
  status: AssetStatusE;
  start_at: string;
  end_at: string;
  user: UserI;
  pack?: PackI;
  subscription?: SubscriptionI;
  created_at: string;
  updated_at?: string;
  tickets?: TicketI[];
}

export interface PaymentI {
  id: number;
  booking_id: number;
  down_payment_id: number;
  vendor_agora_plans_invoice_id: number;
  coupon_id: number;
  payment_link: string;
  payment_link_start_at: Date;
  payment_link_end_at: Date;
  type?: PaymentTypeE;
  status: PaymentStatusE;
  currency?: CurrencyE;
  amount: number;
  down_payment_amount: number;
  down_payment_type?: PaymentTypeE;
  down_payment_at?: Date;
  confirmed_at?: Date;
  discount: number;
  discount_by_payment_method: number;
  discount_by_coupon: number;
  agora_comission: number;
  is_offline_payment_confirmed?: boolean;
  total_amount: number;
  gateway: WalletTypeE;
  created_at: Date;
  updated_at?: Date;
  booking?: BookingI;
  payment_order?: PaymentOrderI;
  vendor_agora_plans_invoice?: VendorAgoraPlanInvoiceI;
  down_payment?: DownPaymentI;
  coupon?: CouponI;
}

export interface TicketI {
  id: number;
  limit: number;
  service_id: number;
  asset_id: number;
  service?: ServiceI;
  asset?: AssetI;
}

export interface InvitationI {
  id: number;
  status: InvitationStatus;
  user_invitee_email: string;
  user_invitor_id: number;
  user_invitee_id: number;
  created_at: Date;
  updated_at?: Date;
  user_invitor: UserI;
  user_invitee: UserI;
}

export interface CrowdfundingPlanI {
  id: number;
  title: string;
  description: string;
  status: CrowdfundingPlanStatusE;
  participants: number;
  amount_to_collect: number;
  show_amount_to_collect: boolean;
  amount_recollected: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
}
export interface CouponI {
  id: number;
  status: CouponStatusE;
  code: string;
  name: string;
  apply_to?: CouponApplyToE;
  amount_discount?: number;
  amount_usd_discount?: number;
  discount?: number;
  quantity: number;
  total_quantity: number;
  end_at: Date;
  user_id: number;
  vendor_id: number;
  service_id?: number;
  subscription_id?: number;
  unique_event_ticket_id?: number;
  digital_content_id?: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  subscription?: SubscriptionI;
  unique_event_ticket?: UniqueEventTicketI;
  digital_content?: DigitalContentI;
  service?: ServiceI;
  user?: UserI;
}

export interface VendorPromptsI {
  id: number;
  is_agenda_working_hours_enabled: boolean;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
}

export interface VendorAgoraPlanI {
  id: number;
  vendor_id: number;
  agora_plan_id: number;
  is_free_trial: boolean;
  is_free_trial_exceeded: boolean;
  is_plan_available: boolean;
  event_fee_percentage?: number;
  digital_content_fee_percentage?: number;
  custom_payment_link?: string;
  payment_recurrence?: SubscriptionRecurrenceE;
  status: VendorAgoraPlanSubscriptionStatusE;
  boxful_subscription_id?: number;
  start_at: Date;
  payment_date?: Date;
  price_monthly: number;
  limit_payment_date?: Date;
  expired_reason?: string;
  mp_subscription_id?: string;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  agora_plan?: AgoraPlanI;
  vendor_agora_plan_invoices?: VendorAgoraPlanInvoiceI[];
}

// ToDo: Add the isActive property on the AgoraPlanI object
export interface AgoraPlanI {
  id: number;
  name: AgoraPlanNameE;
  price_monthly?: number;
  created_at: Date;
  updated_at?: Date;
}

export interface VendorAgoraPlanInvoiceI {
  id: number;
  vendor_agora_plan_id: number;
  status?: VendorAgoraPlanInvoiceStatusE;
  plan_subscription_start_at?: Date;
  plan_subscription_end_at?: Date;
  expiration_date: Date;
  amount: number;
  created_at: Date;
  updated_at?: Date;
  vendor_agora_plan?: VendorAgoraPlanI;
  next_retry_date?: Date;
  debit_date?: Date;
  retry_attempt: number;
  mp_authorized_payment_id?: string;
  boxful_invoice_id?: number;
  payment?: PaymentI;
}

export interface PolygonArea {
  lat: number;
  lng: number;
}
export interface CircleArea {
  center: {
    lat: number;
    lng: number;
  };
  radius: number;
}
export interface CoverageAreaI {
  id: number;
  shape: CoverageAreaShapeE;
  area: PolygonArea[] | CircleArea;
  color: string;
  name: string;
  created_at: Date;
  updated_at?: Date;
  service_id: number;
  service: ServiceI;
}

export interface CardI {
  cardholder: {
    identification: {
      number: string;
      type: string;
    };
    name: string;
  };
  date_created: Date;
  date_last_updated: Date;
  expiration_month: number;
  expiration_year: number;
  first_six_digits: string;
  id: string;
  last_four_digits: string;
}

export interface GoogleCalendarKeyI {
  id: number;
  user_id: string;
  email?: string;
  timezone?: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  created_at: Date;
  updated_at?: Date;
  user: UserI;
}

export interface FAQI {
  id: number;
  name: string;
  description: string;
  order: number;
  service_id?: number;
  subscription_id?: number;
  created_at: Date;
  updated_at?: Date;
  service?: ServiceI;
  subscription?: SubscriptionI;
}

export interface UniqueEventI {
  id: number;
  name: string;
  slug: string;
  description: string;
  address?: string;
  address_details?: string;
  latitude?: string;
  longitude?: string;
  neighborhood?: string;
  country?: SupportedCountriesE;
  state?: string;
  modality: ServiceModalityE;
  duration: number;
  cover_url: string;
  status: UniqueEventStatusE;
  policy_refund_mins: number;
  booking_until_at_minutes: number;
  booking_after_minutes: number;
  meet_type?: MeetTypeE;
  meet_link?: string;
  images_gallery: string[];
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  category_sub_category_id: number;
  vendor?: VendorI;
  category_sub_category?: CategorySubCategoryI;
  unique_events_tickets: UniqueEventTicketI[];
  unique_events_schedules: UniqueEventScheduleI[];
  sold_tickets: number;
  local_slots?: number;
  virtual_slots?: number;
  participants: number;
  is_refund_available: boolean;
  is_policy_available: boolean;
  is_transfer_fee_available: boolean;
  is_booking_until_at_after: boolean;
  is_hidden: boolean;
  password: string;
  is_limited_tickets: boolean;
  max_tickets_quantity: number;
  custom_questions?: CustomQuestionI[];
  is_user_phone_required: boolean;
  faqs?: FAQI[];
}

export interface UniqueEventScheduleI {
  id: number;
  unique_event_id: number;
  start_at: Date | string;
  end_at: Date | string;
  created_at: Date;
  updated_at?: Date;
}

interface CustomQuestionI {
  id: number;
  question: string;
  is_required: boolean;
  order: number;
  service_id: number;
  unique_event_id: number;
  unique_event: UniqueEventI;
  service: ServiceI;
}

export interface UniqueEventTicketI {
  id: number;
  name: string;
  price: number;
  price_usd?: number;
  slots: number;
  is_hidden: boolean;
  modality: ServiceModalityE;
  total_slots: number;
  redeemed_slots: number;
  quantity: number;
  unique_event_id: number;
  parent_ticket_id?: number;
  unique_event: UniqueEventI;
  parent_ticket?: UniqueEventTicketI;
}

export interface OfflinePaymentI {
  id: number;
  type: OfflinePaymentTypeE;
  description: string;
  discount: number;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
}

export interface AgoraThemeColorI {
  background: string | undefined;
  title: string | undefined;
  button: string | undefined;
  text: string | undefined;
  shadow: string | undefined;
}

export interface VendorHeaderInfoI {
  cover_url: string;
  avatar_url: string;
  professional_title: string;
  name: string;
}

export interface VendorStorefrontI {
  id: number;
  vendor_id: number;
  template_name: AgoraThemeE;
  cover_url?: string;
  cover_desktop_url?: string;
  name: string;
  background_color: string;
  title_color: string;
  button_color: string;
  text_color: string;
  button_type: TypeButtonsE;
  border_radius_px: RoundedE;
  font_type: SubtitleTypeFontE;
  title_font_type: TitleTypeFontE;
  created_at: Date;
  updated_at?: Date;
  vendor?: VendorI;
  featured_video_title: string;
  featured_video_url: string;
  shadow_color?: string;
  pattern_type?: PatternE | null;
  is_banner_displayed: boolean;
  is_avatar_displayed: boolean;
  size_banner: SizeImageE;
  size_avatar: SizeImageE;
}

export interface VendorLinkI {
  id: number;
  name: string;
  is_agora_link: boolean;
  agora_link_type?: AgoraLinkTypeE;
  url?: string;
  order: number;
  vendor_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  is_hidden: boolean;
}

export interface SocialAppI {
  id: number;
  name: string;
}

export interface VendorSocialAppI {
  id: number;
  vendor_id: number;
  social_app_id: number;
  link: string;
  order: number;
  created_at: Date;
  updated_at?: Date;
  social_app?: SocialAppI;
  vendor?: VendorI;
}

export interface VendorBlockedHoursI {
  id: number;
  start_at: string;
  start_time: string;
  end_at: string;
  end_time: string;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
}

export interface ParamsRescheduleI {
  id: number;
  duration: number;
  end_at: string;
  end_time: string;
  modality: ServiceModalityE;
  start_at: string;
  start_time: string;
}

export interface FeaturedVideoI {
  title: string;
  url: string;
  error?: string;
}

export interface VendorNextStepsI {
  id: number;
  is_completed: boolean;
  next_step_id: number;
  vendor_id: number;
  next_step: NextStepI;
}
export interface NextStepI {
  id: number;
  name: string;
  url?: string;
  order: number;
  plan_name: AgoraPlanNameE;
}

export interface UniqueEventsInvitations {
  first_name?: string;
  last_name?: string;
  emails: string[];
  unique_event_ticket_id: number;
  quantity: number;
}

export interface PromoterI {
  commission: number;
  email: string;
  id: number;
  name: string;
  phone: string;
  vendor_id: number;
  promoters_events: PromoterEventI[];
  booking_unique_events: BookingUniqueEventI[];
}

export interface PromoterEventI {
  id: number;
  promoter_id: number;
  unique_event_id: number;
  unique_event: UniqueEventI;
  promoter: PromoterI;
}

export interface PromoterAssigned extends PromoterI {
  is_assigned: boolean;
}

export interface BookingNotificationI {
  id: number;
  email_sent: boolean;
  type: BookingNotificationTypeE;
  mailchimp_status?: string;
  delivery_id?: string;
  schedule_id?: number;
  created_at: Date;
  updated_at?: Date;
  user: UserI;
  booking: BookingI;
}

export interface LabelI {
  id: number;
  title: string;
  order: number;
  vendor_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  assets_labels: AssetLabelI[];
}

export interface AssetLabelI {
  id: number;
  service_id: number;
  label_id: number;
  created_at: Date;
  updated_at?: Date;
  service: ServiceI;
  label: LabelI;
}

export interface CategoryI {
  id: number;
  name: string;
  level: number;
  childrens: SubCategoryI[];
}

export interface SubCategoryI {
  id: number;
  name: string;
  level: number;
}

export enum PromoterStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum CurrencyE {
  ARS = 'ars',
  USD = 'usd',
  UYU = 'uyu',
  CLP = 'clp'
}

export enum AgoraThemeE {
  SOHO = 'soho',
  MIXTAPE = 'mixtape',
  CLEAN = 'clean'
}

export enum UniqueEventStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  FINISHED = 'finished',
  DELETED = 'deleted',
  CANCELED = 'canceled'
}

export enum VendorAgoraPlanInvoiceStatusE {
  PENDING = 'pending',
  PAID_OUT = 'paid_out',
  SCHEDULED = 'scheduled',
  RECYCLING = 'recycling',
  CANCELED = 'canceled',
  DUNNING = 'dunning',
  CHARGED_BACK = 'charged_back',
  REFUNDED = 'refunded'
}

export enum OfflinePaymentTypeE {
  CASH = 'cash',
  BANK_TRANSFER = 'bank_transfer'
}

export enum CoverageAreaShapeE {
  CIRCLE = 'circle',
  POLYGON = 'polygon'
}

export enum AgoraPlanNameE {
  EVENT = 'events',
  PROFESSIONAL = 'professional'
}

export enum VendorAgoraPlanSubscriptionStatusE {
  PENDING = 'pending',
  WAITING_FOR_GATEWAY = 'waiting_for_gateway',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired'
}

export enum ScheduleStatusE {
  BOOKABLE = 'bookable',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  FULL = 'full',
  BLOCKED = 'blocked'
}

export enum BookingNotificationTypeE {
  CONFIRMATION = 'confirmation',
  CANCELLATION = 'cancellation',
  RESCHEDULE = 'reschedule',
  EMAIL_REMINDER = 'email_reminder',
  WA_ONLINE_SECOND_REMINDER = 'wa_online_second_reminder',
  WA_LOCAL_SECOND_REMINDER = 'wa_local_second_reminder',
  WA_DUAL_FIRST_REMINDER = 'wa_dual_first_reminder',
  BOOKING_SUBSCRIPTION_REMINDER = 'booking_subscription_reminder'
}

export enum ServiceModalityE {
  LOCAL = 'local',
  VIRTUAL = 'virtual',
  OPEN = 'open'
}

export enum MeetTypeE {
  ZOOM = 'zoom',
  GOOGLE_MEET = 'google_meet'
}

export type BookingStatusT = 'confirmed' | 'pending' | 'canceled' | 'unpaid' | 'refunded';
export enum BookingStatusE {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  REFUNDED = 'refunded',
  COMPLETED = 'completed'
}

export type PaymentStatusT = 'confirmed' | 'pending';
export enum PaymentStatusE {
  PENDING = 'pending',
  DOWN_PAYMENT_PENDING = 'down_payment_pending',
  DOWN_PAYMENT_CONFIRMED = 'down_payment_confirmed',
  CONFIRMED = 'confirmed'
}

export type AssetStatusT = 'confirmed' | 'pending';
export enum AssetStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type PackRecurrenceT = 'monthly';

export type SubscriptionRecurrenceT = 'monthly';
export enum SubscriptionRecurrenceE {
  MONTHLY = 'monthly'
}

export type PaymentTypeT = 'wallet' | 'pack' | 'subscription';
export enum PaymentTypeE {
  WALLET = 'wallet',
  PACK = 'pack',
  SUBSCRIPTION = 'subscription',
  FREE_TRIAL = 'free_trial',
  FREE = 'free',
  VENDOR_INVITE = 'vendor_invite',
  CASH = 'cash',
  BANK_TRANSFER = 'bank_transfer'
}

export enum RefundTypeE {
  PARTIAL = 'partial',
  COMPLETE = 'complete',
  TICKET = 'ticket'
}

export enum DigitalContentStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum BookingPackStatusE {
  PENDING = 'pending',
  ACTIVE = 'active',
  FINISHED = 'finished',
  EXPIRED = 'expired'
}

export enum BookingSubscriptionStatusE {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired'
}

export enum BookingTypeE {
  EVENT_INSTANCE = 'event_instance',
  UNIQUE_EVENT = 'unique_event',
  PACK = 'pack',
  SUBSCRIPTION = 'subscription',
  DONATION = 'donation',
  DIGITAL_CONTENT = 'digital_content'
}

export enum ServiceStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}
export enum SubscriptionStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum Auth0RolesE {
  VENDOR = 'Vendor',
  CUSTOMER = 'Customer',
  ADMIN = 'Admin'
}

export enum WalletTypeE {
  MERCADO_PAGO = 'mercado_pago',
  STRIPE = 'stripe'
}

export enum InvitationStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired'
}

export enum CrowdfundingPlanStatusE {
  ACTIVE = 'active',
  FINISHED = 'finished',
  EXPIRED = 'expired',
  COMPLETED = 'completed'
}

export enum CouponStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted'
}

export enum AgoraLinkTypeE {
  CUSTOM = 'custom',
  SERVICE = 'servicios',
  SUBSCRIPTION = 'suscripciones',
  DIGITAL_CONTENT = 'descargables',
  DONATION = 'bancar',
  EVENT = 'eventos',
  AGENDA = 'agenda',
  ABOUT = 'bio',
  YOUTUBE = 'youtube',
  MAPS = 'maps'
}

export enum SizeImageE {
  SMALL = 'small',
  BIG = 'big'
}

export enum CouponApplyToE {
  ALL = 'all',
  PACKS = 'packs',
  SERVICES = 'services',
  SUBSCRIPTIONS = 'subscriptions',
  UNIQUE_EVENTS = 'unique_events',
  DIGITAL_CONTENTS = 'digital_contents'
}

export enum SupportedCountriesE {
  ARGENTINA = 'Argentina',
  URUGUAY = 'Uruguay',
  CHILE = 'Chile'
}
