import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';

const buttonVariants = (
  variant: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'muted' | 'link',
  size?: 'default' | 'sm' | 'lg' | 'icon'
) => {
  const baseClasses =
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50';

  const variantClasses = {
    default: 'bg-blue text-white hover:bg-blue/90',
    destructive: 'bg-[#FF4658] text-white hover:bg-[#FF4658]/90',
    outline: 'border border-input bg-background hover:bg-gray-100',
    secondary: 'bg-[#212121] text-white hover:bg-[#212121]/80',
    ghost: 'bg-background text-muted-foreground',
    muted: '!font-normal bg-[#F4F4F4] text-[#868686]',
    link: 'text-blue underline-offset-4 hover:underline'
  };

  const sizeClasses = {
    default: 'h-10 px-4 py-2',
    sm: 'h-9 rounded-md px-3',
    lg: 'h-11 rounded-md px-8',
    icon: 'h-10 w-10'
  };

  return classNames(baseClasses, variantClasses[variant], sizeClasses[size || 'default']);
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'muted' | 'link';
  size?: 'default' | 'sm' | 'lg' | 'icon';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'default', asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={classNames(buttonVariants(variant, size), className)} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
