import React, { FunctionComponent, useEffect } from 'react';
import { useAuth } from '@/contexts';
import { Column } from '../Column';
import classNames from 'classnames';
import { Row } from '../Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { Button } from '../Button';
import { Transition } from '@headlessui/react';
import { LINKS } from '@/constants';

type PricingPlansProps = {
  onSubmit: (planId: number, staffCount?: number) => void;
  country?: SupportedCountriesE;
  isLoading: boolean;
  isOnboarding?: boolean;
  vendors?: number;
};

export const PricingPlans: FunctionComponent<PricingPlansProps> = ({
  onSubmit,
  country = SupportedCountriesE.ARGENTINA,
  isLoading,
  isOnboarding = false,
  vendors
}) => {
  const { session } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [numberOfProfessionals, setNumberOfProfessionals] = React.useState<number>(
    isOnboarding ? 0 : vendors || 0
  );

  useEffect(() => {
    if (!isOnboarding && vendors) {
      setNumberOfProfessionals(vendors);
    }
  }, [vendors]);
  const isReferred = session?.is_invited || false;

  const isActualPlan = numberOfProfessionals === vendors;

  const handleSubmit = () => {
    setLoading(true);
    const plandId = numberOfProfessionals === 1 ? 1 : 2;
    onSubmit(plandId, numberOfProfessionals);
  };

  const handleContact = () => {
    const message = `Hola! Me gustaría cambiar mi plan. ¿Podrían ayudarme?`;

    window.open(`${LINKS.SUPPORT_WHATSAPP}?text=${encodeURIComponent(message)}`, '_blank');
  };

  const getPricesByCountry = (country: string) => {
    switch (country) {
      case 'Chile':
        return {
          professionalPrice: 9900,
          businessBasePrice: 19900,
          businessStaffPrice: 3000,
          referralDiscount: 2000
        };
      case 'Uruguay':
        return {
          professionalPrice: 449,
          businessBasePrice: 749,
          businessStaffPrice: 150,
          referralDiscount: 100
        };
      default:
        return {
          professionalPrice: 7900,
          businessBasePrice: 11900,
          businessStaffPrice: 3000,
          referralDiscount: 1000
        };
    }
  };

  const { professionalPrice, businessBasePrice, businessStaffPrice, referralDiscount } =
    getPricesByCountry(country);

  const calculateAdditionalPrice = (count: number): number => {
    if (count <= 2) {
      return 0;
    } else {
      return businessStaffPrice + calculateAdditionalPrice(count - 1);
    }
  };

  const professionalAdditionalPrice =
    numberOfProfessionals > 1 ? calculateAdditionalPrice(numberOfProfessionals) : 0;
  const businessPlan = professionalAdditionalPrice
    ? businessBasePrice + professionalAdditionalPrice
    : businessBasePrice;

  const getPrice = () => {
    if (numberOfProfessionals === 1) return professionalPrice;
    else return businessPlan;
  };

  useEffect(() => {
    getPrice();
  }, [numberOfProfessionals]);

  const getSymbolFromCountry = (country: string) => {
    switch (country) {
      case 'Chile':
        return 'CLP';
      case 'Uruguay':
        return 'U$';
      default:
        return '$';
    }
  };

  if (isOnboarding)
    return (
      <section>
        <div className="flex flex-col gap-6 justify-center items-center w-full h-auto sm:w-[350px] mx-auto">
          <Row
            align="center"
            justify="space-between"
            gap={16}
            className="border bg-white rounded-lg h-[46px] p-4 sm:text-sm w-full"
          >
            <div
              onClick={() => setNumberOfProfessionals(numberOfProfessionals - 1)}
              className={classNames('cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded-lg', {
                'pointer-events-none cursor-not-allowed bg-[#E9E9E9]': numberOfProfessionals <= 0,
                'cursor-pointer': numberOfProfessionals > 2
              })}
            >
              <FontAwesomeIcon icon={faMinus} size="1x" color="#000" />
            </div>
            <span className="font-medium text-xl sm:text-lg min-w-10">
              {numberOfProfessionals} profesional
              {numberOfProfessionals > 1 || numberOfProfessionals === 0 ? 'es' : ''}
            </span>
            <div
              onClick={() => setNumberOfProfessionals(numberOfProfessionals + 1)}
              className={classNames('cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded', {
                invisible: numberOfProfessionals >= 15,
                visible: numberOfProfessionals < 15
              })}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" color="#000" />
            </div>
          </Row>
          <Transition
            show={numberOfProfessionals > 0}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-auto relative mb-6">
              <Column
                justify="space-between"
                className={classNames('p-4 h-full bg-white border border-[#DADCE0] shadow-lg rounded-lg')}
              >
                <div className="flex flex-row sm:flex-col w-full justify-between px-2 sm:px-0">
                  <Column gap={16}>
                    <h2 className="text-3xl">{numberOfProfessionals > 1 ? 'Negocio' : 'Profesional'}</h2>
                    <p className="font-medium text-base sm:h-[72px] text-blue">
                      {numberOfProfessionals > 1
                        ? 'Para centros, salones y consultorios. Gestión integral de turnos para equipos.'
                        : 'Para profesionales: reserva de turnos y venta de capacitaciones'}
                    </p>
                    <Row>
                      <p className="font-bold text-base mt-2">{getSymbolFromCountry(country)}</p>
                      <Row align="center">
                        <p className="text-5xl font-bold">
                          {isReferred
                            ? (getPrice() - referralDiscount).toLocaleString('es-AR')
                            : getPrice().toLocaleString('es-AR')}
                        </p>
                        <span className="text-base font-bold mt-5">/mes</span>
                      </Row>
                    </Row>
                    <Row gap={16} align="baseline">
                      {isReferred ? (
                        <>
                          <p className="text-[#FF4658] text-lg line-through">
                            {getPrice().toLocaleString('es-AR')}/mes
                          </p>
                          <p className="text-[#868686] text-sm">IVA incluido.</p>
                        </>
                      ) : (
                        <p className="text-[#868686] text-sm">IVA incluido.</p>
                      )}
                    </Row>
                  </Column>
                </div>
              </Column>
            </div>
            <Button
              onClick={handleSubmit}
              loading={isLoading || loading}
              size="lg"
              className="!w-full !text-lg"
            >
              Empezá gratis
            </Button>
          </Transition>
        </div>
        <div className="flex flex-col bg-blue/10 max-w-[720px] w-full items-center m-auto mt-6 py-2 px-2 border shadow-md border-[#DADCE0]">
          <Row gap={16} align="center" className="py-2 px-4 text-lg w-full">
            <FontAwesomeIcon icon={faCheck} color="#000" />
            <span className="text-lg">
              ¡Los planes cuentan con <b>reservas de turnos ilimitadas, sin comisiones!</b>
            </span>
          </Row>
          <Row gap={16} align="center" className="py-2 px-4 text-lg w-full">
            <FontAwesomeIcon icon={faCheck} color="#000" />
            <span className="text-lg">
              Contás con 14 días de prueba gratis para familiarizarte con la plataforma
            </span>
          </Row>
        </div>
      </section>
    );
  else
    return (
      <div className="flex flex-col sm:flex-row items-center justify-center gap-8 py-4 mx-auto w-full sm:h-[calc(100dvh-104px)]">
        <div className="flex flex-col gap-6 justify-center items-center w-full h-auto sm:w-[350px]">
          <Column
            justify="space-between"
            className={classNames('p-4 h-full bg-white border border-[#DADCE0] shadow-lg rounded-lg', {
              'border-blue': vendors && vendors === 1
            })}
          >
            <div className="flex flex-row sm:flex-col w-full justify-between px-2 sm:px-0">
              <Column gap={16}>
                <h2 className="text-3xl">Profesional</h2>
                <p className="font-medium text-base sm:h-[72px] text-blue">
                  Para profesionales: reserva de turnos y venta de capacitaciones
                </p>
                <div className="h-[46px]" />
                <Column>
                  <Row>
                    <p className="font-bold text-base mt-2">{getSymbolFromCountry(country)}</p>
                    <Row align="center">
                      <p className="text-5xl font-bold">
                        {isReferred
                          ? (professionalPrice - referralDiscount).toLocaleString('es-AR')
                          : professionalPrice.toLocaleString('es-AR')}
                      </p>
                      <span className="text-base font-bold mt-5">/mes</span>
                    </Row>
                  </Row>
                  <Row gap={4} align="baseline">
                    {isReferred ? (
                      <>
                        <p className="text-[#FF4658] text-lg line-through">
                          {getPrice().toLocaleString('es-AR')}/mes
                        </p>
                        <p className="text-[#868686] text-sm">IVA incluido.</p>
                      </>
                    ) : (
                      <p className="text-[#868686] text-sm">IVA incluido.</p>
                    )}
                  </Row>
                </Column>
                <Button
                  onClick={handleContact}
                  loading={isLoading || loading}
                  size="lg"
                  variant={vendors === 1 ? 'filled' : 'outlined'}
                  disabled={vendors === 1}
                  className="!w-full !text-lg"
                >
                  {vendors === 1 ? 'Plan actual' : 'Cambiar plan'}
                </Button>
              </Column>
            </div>
          </Column>
        </div>
        <div className="flex flex-col gap-6 justify-center items-center w-full h-auto sm:w-[350px]">
          <Column
            justify="space-between"
            className={classNames('p-4 h-full bg-white border border-[#DADCE0] shadow-lg rounded-lg', {
              'border-blue': vendors && vendors > 2
            })}
          >
            <div className="flex flex-row sm:flex-col w-full justify-between px-2 sm:px-0">
              <Column gap={16}>
                <h2 className="text-3xl">Negocio</h2>
                <p className="font-medium text-base sm:h-[72px] text-blue">
                  {numberOfProfessionals > 1
                    ? 'Para centros, salones y consultorios. Gestión integral de turnos para equipos.'
                    : 'Para profesionales: reserva de turnos y venta de capacitaciones'}
                </p>
                <Row
                  align="center"
                  justify="space-between"
                  gap={16}
                  className="border bg-white rounded-lg h-[46px] p-4 sm:text-sm w-full"
                >
                  <div
                    onClick={() => setNumberOfProfessionals(numberOfProfessionals - 1)}
                    className={classNames(
                      'cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded-lg',
                      {
                        'pointer-events-none cursor-not-allowed bg-[#E9E9E9]': numberOfProfessionals <= 2,
                        'cursor-pointer': numberOfProfessionals > 2
                      }
                    )}
                  >
                    <FontAwesomeIcon icon={faMinus} size="1x" color="#000" />
                  </div>
                  <span className="font-medium text-xl sm:text-lg min-w-10">
                    {numberOfProfessionals} profesional
                    {numberOfProfessionals > 1 || numberOfProfessionals === 0 ? 'es' : ''}
                  </span>
                  <div
                    onClick={() => setNumberOfProfessionals(numberOfProfessionals + 1)}
                    className={classNames(
                      'cursor-pointer shadow-md py-1 px-3 border border-[#E9E9E9] rounded',
                      {
                        invisible: numberOfProfessionals >= 15,
                        visible: numberOfProfessionals < 15
                      }
                    )}
                  >
                    <FontAwesomeIcon icon={faPlus} size="1x" color="#000" />
                  </div>
                </Row>
                <Column>
                  <Row>
                    <p className="font-bold text-base mt-2">{getSymbolFromCountry(country)}</p>
                    <Row align="center">
                      <p className="text-5xl font-bold">
                        {isReferred
                          ? (getPrice() - referralDiscount).toLocaleString('es-AR')
                          : getPrice().toLocaleString('es-AR')}
                      </p>
                      <span className="text-base font-bold mt-5">/mes</span>
                    </Row>
                  </Row>
                  <Row gap={8} align="baseline">
                    {isReferred ? (
                      <>
                        <p className="text-[#FF4658] text-lg line-through">
                          {getPrice().toLocaleString('es-AR')}/mes
                        </p>
                        <p className="text-[#868686] text-sm">IVA incluido.</p>
                      </>
                    ) : (
                      <p className="text-[#868686] text-sm">IVA incluido.</p>
                    )}
                  </Row>
                </Column>
                <Button
                  onClick={handleContact}
                  loading={isLoading || loading}
                  disabled={isActualPlan}
                  size="lg"
                  className="!w-full !text-lg"
                  variant={vendors === 1 ? 'outlined' : 'filled'}
                >
                  {isActualPlan ? 'Plan actual' : vendors === 1 ? 'Cambiar plan' : 'Actualizar plan'}
                </Button>
              </Column>
            </div>
          </Column>
        </div>
      </div>
    );
};
