import { Row } from '@/components';
import { Button } from '@/components/Button/ButtonV2';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContentHalfBottom,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { EmptyObject, ErrorI } from '@/types/cyclone/requests';
import { useMutation } from 'react-query';
import { useClient, useWindowResize } from '@/hooks';
import useLog from '@/hooks/useLog';
import { useAuth } from '@/contexts';
import { AgoraPlanNameE } from '@/types/cyclone/models';

type Props = {
  id: number;
  type: string;
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  onClose: () => void;
};

export type FormValues = {
  dates: { date: Date; start: string; end: string }[];
};

export const ModalCancel: FunctionComponent<Props> = ({ id, modalOpen, setModalOpen, type, onClose }) => {
  const { client } = useClient();
  const { session } = useAuth();
  const { logAndNotify } = useLog();
  const { isMobileSize } = useWindowResize();

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;

  const mutationDelete = useMutation<EmptyObject, ErrorI>(
    () =>
      client(`unique_events/${id}`, 'DELETE', {
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        toast.success(`${isEventPlan ? 'Evento' : 'Capacitación'} eliminado exitosamente`);
        onClose();
        setModalOpen(false);
      },
      onError: (e) => {
        if (e.code && e.code === 'G-001') {
          toast.error(`Este ${isEventPlan ? 'evento' : 'capacitación'} tiene usuarios`);
          setModalOpen(false);
        } else {
          logAndNotify(`Error al eliminar un evento - ${e}`);
        }
      }
    }
  );

  const contentRaw = (
    <>
      <DialogHeader className="mb-4">
        <div className="grid grid-cols-4 w-full">
          <div />
          <DialogTitle className="col-span-2">
            {type} {isEventPlan ? 'evento' : 'capacitación'}
          </DialogTitle>
          <DialogClose className="place-self-end" />
        </div>
      </DialogHeader>
      <DialogDescription className="text-[#868686] pb-4 mb-4 border-b">
        Si no puedes organizar tu {isEventPlan ? 'evento' : 'capacitación'}, puedes cancelarlo y nosotros
        notificaremos a tus invitados.
      </DialogDescription>
      <DialogFooter>
        <Row gap={16} align="center">
          <Button
            variant="muted"
            onClick={() => setModalOpen(false)}
            type="button"
            className="flex items-center max-w-fit"
          >
            <span>Volver</span>
          </Button>
          <Button variant="destructive" onClick={() => mutationDelete.mutate()}>
            {type} {isEventPlan ? 'evento' : 'capacitación'}
          </Button>
        </Row>
      </DialogFooter>
    </>
  );

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={() => {
        setModalOpen(false);
      }}
    >
      {isMobileSize ? (
        <DialogContentHalfBottom>{contentRaw}</DialogContentHalfBottom>
      ) : (
        <DialogContent>{contentRaw}</DialogContent>
      )}
    </Dialog>
  );
};
