import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Column, InputDatepicker, Row, Select } from '@/components';
import {
  BookingI,
  EventInstanceI,
  ParamsRescheduleI,
  ServiceModalityE,
  UserI,
  VirtualEventInstancesI
} from '@/types/cyclone/models';
import { LabelValue } from '@/types';
import { buildSelectOptions } from '@/components/Select';
import dayjs from 'dayjs';
import { getDatesInterval, getTime } from '@/utils';
import { useMutation, useQuery } from 'react-query';
import {
  ErrorI,
  GetVirtualSchedulesAvailabilityI,
  GetVirtualSchedulesI,
  VirtualSchedulesAvailabilityI
} from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { InputTimePicker } from '@/components/Input';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { convertToSpecifiedTimezone } from '@/utils/schedule';
import { useAuth } from '@/contexts';

type ContactModalProps = {
  closeModal: () => void;
  showModal: boolean;
  contactTo?: string;
  eventInstance: VirtualEventInstancesI | EventInstanceI;
  user: UserI | undefined;
  booking: BookingI | undefined;
  vendorId?: number;
  refetch: () => void;
};

export const RescheduleModal: FunctionComponent<ContactModalProps> = ({
  closeModal,
  showModal,
  eventInstance,
  user,
  booking,
  vendorId,
  refetch
}) => {
  const [selectedHour, setSelectedHour] = useState<LabelValue | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startDate, endDate] = getDatesInterval({ interval: 60 });
  const [hour, setHour] = useState<string | undefined>(undefined);

  const { client } = useClient();
  const { session } = useAuth();
  const navigate = useNavigate();

  const isOnDemand = eventInstance.event.service.is_on_demand;

  useEffect(() => {
    if (eventInstance.start_at) setSelectedDate(dayjs(eventInstance.start_at).toDate());
    if (eventInstance.start_time) setHour(eventInstance.start_time);
  }, [eventInstance.start_at]);

  const mutation = useMutation<any, ErrorI, ParamsRescheduleI>(
    (params) =>
      client(`events/instances/${params.id}/reschedule`, 'POST', {
        isAuthRequired: true,
        data: params
      }),
    {
      onSuccess: () => {
        closeModal();
        toast.success('Se modificó la reserva exitosamente');
        refetch();
        navigate('/agenda');
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const { data: schedules } = useQuery(
    ['services', eventInstance.event.service.id, 'schedules', startDate, endDate],
    async () =>
      await client<GetVirtualSchedulesI[]>(
        `services/${eventInstance.event.service.id}/schedules/${startDate}/${endDate}`
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      // FIXME: handle error case
      onError: () => null
    }
  );

  const { data: availabilities, isFetching } = useQuery(
    [
      'services',
      eventInstance.event.service.id,
      'availability',
      dayjs(selectedDate).format('YYYY-MM-DD'),
      dayjs(selectedDate).format('YYYY-MM-DD')
    ],
    async () =>
      await client<GetVirtualSchedulesAvailabilityI[]>(
        `services/${eventInstance.event.service.id}/schedules/${dayjs(selectedDate).format(
          'YYYY-MM-DD'
        )}/${dayjs(selectedDate).format('YYYY-MM-DD')}/availability`
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!selectedDate,
      // FIXME: handle error case
      onError: () => null,
      select: (data) => convertToSpecifiedTimezone(data, session?.vendor?.timezone)
    }
  );

  const handleOnSubmit = () => {
    if (availabilities && selectedDate && (selectedHour || hour) && booking) {
      if (isOnDemand) {
        const endTime = dayjs()
          .set('hour', parseInt(hour!.split(':')[0]))
          .set('minute', parseInt(hour!.split(':')[1]))
          .add(eventInstance.event.service.duration, 'minute')
          .format('HH:mm');
        const eventInstanceParams = {
          start_at: dayjs(selectedDate).format('YYYY-MM-DD'),
          end_at: dayjs(selectedDate).format('YYYY-MM-DD'),
          is_customer: false,
          start_time: hour,
          end_time: endTime,
          modality: eventInstance.modality,
          id: booking!.booking_event_instance!.id,
          vendor_id: vendorId
        };

        mutation.mutate(eventInstanceParams as any);
      } else {
        const schedule = availabilities.find(
          ({ start_at, start_time }) =>
            start_at === dayjs(selectedDate).format('YYYY-MM-DD') && start_time === selectedHour!.value
        );
        const eventInstanceParams = {
          ...schedule,
          is_customer: false,
          modality: eventInstance.modality,
          id: booking!.booking_event_instance!.id,
          vendor_id: vendorId
        };

        mutation.mutate(eventInstanceParams as any);
      }
    }
  };

  const getDates = () => {
    if (schedules)
      return schedules
        .filter((schedule, index, self) => self.findIndex((t) => t.start_at === schedule.start_at) === index)
        .filter((schedule) => {
          if (schedule.modality === ServiceModalityE.OPEN) return schedule;
          else return schedule.modality === eventInstance.modality;
        })
        .sort((a, b) => (dayjs(a.start_at).isAfter(dayjs(b.start_at)) ? 1 : -1))
        .map((schedule) => {
          return schedule.start_at;
        });
    return [];
  };

  const getHours = (date?: string) => {
    const dateToFilter = date || dayjs(selectedDate).format('YYYY-MM-DD');

    if (!availabilities || availabilities.length === 0)
      return [
        {
          label: `No hay disponibilidad`,
          value: 'not_available'
        }
      ];

    return availabilities
      .filter((availability) => availability.start_at === dateToFilter)
      .sort((a, b) => {
        const getHour = (availability: VirtualSchedulesAvailabilityI) =>
          parseInt(availability.start_time.split(':')[0]);
        const getMinutes = (availability: VirtualSchedulesAvailabilityI) =>
          parseInt(availability.start_time.split(':')[1]);

        return dayjs(a.start_at)
          .set('hours', getHour(a))
          .set('minutes', getMinutes(a))
          .isAfter(dayjs(b.start_at).set('hours', getHour(b)).set('minutes', getMinutes(b)))
          ? 1
          : -1;
      })
      .map((availability) => {
        const time = getTime(availability.start_time);
        return {
          label: `${time} hs`,
          value: availability.start_time
        };
      });
  };

  const scheduleOptions = buildSelectOptions(getHours(), selectedHour, setSelectedHour);

  return (
    <Dialog open={showModal} onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <div className="grid grid-cols-4 w-full">
            <div />
            <DialogTitle className="col-span-2">Reagendar reserva</DialogTitle>
            <DialogClose className="place-self-end" />
          </div>
        </DialogHeader>
        <DialogDescription className="pb-4 mb-4">
          <Column className="border-b-[1px] mt-2 pb-4 mb-8 border-[#ECEEF0]">
            <p className="text-[#757575] text-base">
              Cliente: {user?.first_name} {user?.last_name}
            </p>
            <p className="text-[#757575] text-base">{eventInstance.event.service.name}</p>
            <p className="text-[#757575] text-base">
              {dayjs(eventInstance.start_at).format('dddd').charAt(0).toUpperCase() +
                dayjs(eventInstance.start_at).format('dddd').slice(1)}{' '}
              {dayjs(eventInstance.start_at).format('DD [de] MMMM')}
              {', '}
              {eventInstance.start_time.substring(0, 5)}
            </p>
          </Column>
        </DialogDescription>
        <Row gap={10} className="mb-10">
          {isOnDemand ? (
            <>
              <div className="w-1/2">
                <InputDatepicker
                  date={eventInstance.start_at}
                  setDate={(date) => setSelectedDate(date)}
                  className="text-lg cursor-pointer w-fit rounded hover:text-[#0072FB] focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                  datepickerProps={
                    !isOnDemand
                      ? {
                          includeDates: getDates().map((date) => dayjs(date).toDate())
                        }
                      : undefined
                  }
                />
              </div>
              <div className="w-1/2">
                <InputTimePicker initialValue={hour} onTimeChange={(time) => setHour(time)} />
              </div>
            </>
          ) : (
            <>
              <div className="w-1/2">
                <InputDatepicker
                  date={eventInstance.start_at}
                  setDate={(date) => setSelectedDate(date)}
                  className="text-lg cursor-pointer w-fit rounded hover:text-[#0072FB] focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                  datepickerProps={{
                    includeDates: getDates().map((date) => dayjs(date).toDate())
                  }}
                />
              </div>
              <div className="w-1/2">
                <Select
                  value={selectedHour?.label}
                  placeholder="Horario"
                  options={scheduleOptions}
                  disabled={!selectedDate || selectedHour?.value === 'not_available'}
                  isLoading={isFetching}
                  fullWidth
                  minimumScroll
                />
              </div>
            </>
          )}
        </Row>
        <DialogFooter>
          <Button
            loading={mutation.isLoading}
            fullWidth
            onClick={() => handleOnSubmit()}
            disabled={selectedHour?.value === 'not_available'}
          >
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
