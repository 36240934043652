import React, { useEffect, useMemo } from 'react';
import { Column, Row } from '@/components';
import { useOnboarding } from '@/containers/SelfOnboarding/contexts';
import { useWindowResize } from '@/hooks';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { InputAddress } from '@/components/Input/InputAddress';
import { NextStepButton } from '../components/Navbar/Navbar';
import { Map } from '@/components/Map';
import TextArea from '@/components/TextArea/TextArea';
import { countryOptions, SelectProps, statesOptions, timezoneOptions } from '../utils/locations';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';

export const Location: React.FC = () => {
  const {
    selectedPlace,
    handleSelectPlace,
    notPlaceSelected,
    handleNotPlaceSelected,
    country,
    setCountry,
    state,
    setState,
    timezone,
    setTimeZone,
    addressDetails,
    setAddressDetails
  } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  const [formState, setFormState] = React.useState({
    country: null as SelectProps | null,
    state: null as SelectProps | null,
    timezone: null as SelectProps | null,
    additionalDetails: ''
  });

  // Initialize form state with existing data
  useEffect(() => {
    if (country) {
      const selectedCountry = countryOptions.find((option) => option.value === country) || {
        value: country,
        label: '🌎 Otro'
      };

      setFormState((prev) => ({
        ...prev,
        country: selectedCountry
      }));

      // Initialize state if it exists
      if (state) {
        const selectedState = statesOptions[country as SupportedCountriesE]?.find(
          (option) => option.value === state || option.label === state
        ) || {
          value: '',
          label: ''
        };

        setFormState((prev) => ({
          ...prev,
          state: selectedState
        }));
      }

      if (addressDetails) {
        setFormState((prev) => ({
          ...prev,
          additionalDetails: addressDetails
        }));
      }

      // Initialize timezone for supported countries
      if (timezone) {
        const selectedTimezone = timezoneOptions[country as SupportedCountriesE].find(
          (option) => option.value === timezone
        );
        if (selectedTimezone) {
          setFormState((prev) => ({
            ...prev,
            timezone: selectedTimezone
          }));
          setTimeZone(selectedTimezone.value as string);
        }
      } else {
        if (Object.values(SupportedCountriesE).includes(country as SupportedCountriesE)) {
          const countryTimezones = timezoneOptions[country as SupportedCountriesE];
          if (countryTimezones?.length === 1) {
            const defaultTimezone = countryTimezones[0];
            setFormState((prev) => ({
              ...prev,
              timezone: defaultTimezone
            }));
            setTimeZone(defaultTimezone.value as string);
          }
        }
      }
    }
  }, [country, state, setTimeZone, addressDetails, timezone]);

  const handleSelectCountry = (selectedValue: string) => {
    const selected = countryOptions.find((option) => option.value === selectedValue);
    if (!selected) return;

    setFormState((prev) => ({
      ...prev,
      country: selected,
      state: null,
      timezone: null
    }));

    setCountry(selectedValue as SupportedCountriesE);

    const countryTimezones = timezoneOptions[selectedValue as SupportedCountriesE];
    if (countryTimezones?.length === 1) {
      setFormState((prev) => ({
        ...prev,
        timezone: countryTimezones[0]
      }));
      setTimeZone(countryTimezones[0].value as string);
    }
  };

  const handleSelectState = (selectedValue: string) => {
    if (!formState.country) return;

    const selected = statesOptions[formState.country.value as SupportedCountriesE].find(
      (option) => option.value === selectedValue
    );

    if (selected) {
      setFormState((prev) => ({
        ...prev,
        state: selected
      }));
      setState(selected.label);
    }
  };

  const handleSelectTimezone = (selectedValue: string) => {
    if (!formState.country) return;

    const selected = timezoneOptions[formState.country.value as SupportedCountriesE].find(
      (option) => option.value === selectedValue
    );

    if (selected) {
      setFormState((prev) => ({
        ...prev,
        timezone: selected
      }));
      setTimeZone(selectedValue);
    }
  };

  const handleAdditionalDetails = (value: string) => {
    setFormState((prev) => ({
      ...prev,
      additionalDetails: value
    }));
    setAddressDetails(value);
  };

  const buildLabel = useMemo(() => {
    const labels: Record<string, string> = {
      [SupportedCountriesE.CHILE]: 'región',
      [SupportedCountriesE.URUGUAY]: 'departamento'
    };

    return formState.country?.value ? labels[formState.country.value] || 'provincia' : 'provincia';
  }, [formState.country?.value]);

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)]': !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-3xl mt-4 text-center text-balance">Ubicación de tu negocio</h2>
      <p className="text-base text-[#626262] font-medium mt-2 text-center text-balance">
        Asignaremos esta dirección como predeterminada a tus servicios presenciales.
      </p>

      <Column gap={16} className="md:w-1/2 w-full py-4 px-6 mt-4">
        <p className="font-medium text-lg">Selecciona el país donde te encuentras</p>
        <Select
          value={formState.country?.value || ''}
          onValueChange={handleSelectCountry}
          disabled={!!selectedPlace}
        >
          <SelectTrigger className="w-full !text-base !border-[#0000005c]">
            <SelectValue placeholder="Selecciona un país">
              {formState.country?.label || 'Selecciona un país'}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {countryOptions.map((option) => (
              <SelectItem key={option.value} value={option.value as string}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {formState.country?.value && formState.country.value !== SupportedCountriesE.CHILE && (
          <>
            <p className="font-medium text-lg">Selecciona tu {buildLabel}</p>
            <Select
              value={formState.state?.value || ''}
              onValueChange={handleSelectState}
              disabled={!!selectedPlace}
            >
              <SelectTrigger className="w-full !text-base !border-[#0000005c]">
                <SelectValue placeholder={`Selecciona tu ${buildLabel}`}>
                  {formState.state?.label || `Selecciona tu ${buildLabel}`}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {statesOptions[formState.country.value as SupportedCountriesE].map((option) => (
                  <SelectItem key={option.value} value={option.value as string}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}

        <Transition
          show={formState.country?.value === SupportedCountriesE.CHILE}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <p className="font-medium text-lg mb-4">Selecciona tu zona horaria</p>
          <Select
            value={formState.timezone?.value || ''}
            onValueChange={handleSelectTimezone}
            disabled={!!selectedPlace}
          >
            <SelectTrigger className="w-full !text-base !border-[#0000005c]">
              <SelectValue placeholder="Selecciona tu zona horaria">
                {formState.timezone?.label || 'Selecciona tu zona horaria'}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {formState.country &&
                timezoneOptions[formState.country.value as SupportedCountriesE].map((option) => (
                  <SelectItem key={option.value} value={option.value as string}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </Transition>

        <Transition
          show={
            !!formState.country?.value &&
            (formState.country.value === SupportedCountriesE.CHILE
              ? !!formState.timezone?.value
              : !!formState.state?.value)
          }
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <p className="font-medium text-lg mb-4">Ingresa la dirección de tu negocio</p>
          <InputAddress
            selectedPlace={selectedPlace}
            onSelect={handleSelectPlace}
            country={country}
            disabled={notPlaceSelected}
            state={formState.state?.label}
            isOnboarding
          />
          <div className="pt-4">
            <TextArea
              placeholder="Piso, departamento, otras instrucciones"
              value={formState.additionalDetails}
              onChange={handleAdditionalDetails}
              autoExpand={false}
              maxLength={70}
              showCount
              disabled={notPlaceSelected}
            />
          </div>
          {selectedPlace && (
            <div className="py-4">
              <Map height="300px" address={selectedPlace} zoom={15} />
            </div>
          )}
        </Transition>
        <Row className="form-control mt-2 mb-4">
          <label className="label gap-2 p-0 cursor-pointer">
            <input
              type="checkbox"
              checked={notPlaceSelected}
              onChange={handleNotPlaceSelected}
              className="checkbox w-5 h-5 rounded checkbox-primary"
            />
            <span className="text-base font-book">
              Sólo brindo servicios online / no tengo una dirección específica.
            </span>
          </label>
        </Row>

        <NextStepButton fullWidth />
      </Column>
    </Column>
  );
};

export default Location;
