import { faCropSimple, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

type ImageActionButtonProps = {
  handleDelete?: () => void;
  handleCrop: () => void;
  avatar?: boolean;
};

export const ImageActionButton: FunctionComponent<ImageActionButtonProps> = ({
  handleDelete,
  handleCrop,
  avatar
}) => {
  return (
    <div
      className={classNames('absolute', {
        'top-0 right-0': avatar,
        'top-4 right-4': !avatar
      })}
    >
      <button
        onClick={handleCrop}
        className={classNames('rounded-full h-10 w-10 flex items-center justify-center', {
          'bg-blue-500 hover:bg-blue-500': avatar,
          'bg-gray-700 hover:bg-blue-500': !avatar
        })}
      >
        <FontAwesomeIcon icon={faCropSimple} color="white" />
      </button>
      {handleDelete && (
        <button
          onClick={handleDelete}
          className="ml-2 rounded-full h-10 w-10 flex items-center justify-center bg-gray-700 hover:bg-blue-500"
        >
          <FontAwesomeIcon icon={faTrash} color="white" />
        </button>
      )}
    </div>
  );
};
