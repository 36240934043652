import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useClient } from '@/hooks';
import { ScheduleStatusT, VirtualEventInstancesI } from '@/types/cyclone/models';
import { EventActions } from './EventActions';
import { EventActionsMobile } from './EventActionsMobile';
import { CreateBookingInstance, ErrorI } from '@/types/cyclone/requests';

interface EventHeaderProps {
  name: string;
  status: ScheduleStatusT;
  mobile?: boolean;
  eventInstance: VirtualEventInstancesI;
  startAt: string;
  startTime: string;
  refetchEI: () => void;
}
type EmptyObject = {
  [K in string]: never;
};

export const EventHeader: FunctionComponent<EventHeaderProps> = ({
  name,
  status,
  mobile,
  eventInstance,
  startAt,
  startTime,
  refetchEI
}) => {
  const { client } = useClient();

  const mutation = useMutation<EmptyObject, ErrorI, { message: string }>(
    (message) =>
      client(
        `events/virtual_instances/${eventInstance.event.id}/contact?start_at=${startAt}&start_time=${startTime}`,
        'POST',
        {
          isAuthRequired: true,
          data: message
        }
      ),
    {
      onSuccess: () => {
        toast.success('Mensaje enviado a los participantes');
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );
  const mutationBookUser = useMutation<EmptyObject, ErrorI, CreateBookingInstance>(
    (data) =>
      client(`events/virtual_instances/${eventInstance.event.id}/book`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Se ha reservado al client exitosamente');
        refetchEI();
      },
      onError: (e) => {
        if (e.code && e.code === 'EV-008') {
          toast.error('Este usuario no existe');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const handleContactAllSubmit = (message: string) => {
    if (eventInstance.id && eventInstance.participants > 0) {
      mutation.mutate({ message });
    } else {
      toast.error('El evento necesita tener mas de 1 participante');
    }
  };
  const handleBookUser = (email: string, modality: string) => {
    const data: CreateBookingInstance = {
      service_id: eventInstance.event.service_id.toString(),
      start_at: eventInstance.start_at,
      end_at: eventInstance.end_at,
      start_time: eventInstance.start_time,
      end_time: eventInstance.end_time,
      modality,
      email
    };
    mutationBookUser.mutate(data);
  };

  return (
    <EventHeaderContainer>
      {mobile ? (
        <EventActionsMobile
          onSubmitContactAll={handleContactAllSubmit}
          onSubmitBookUser={handleBookUser}
          eventInstance={eventInstance}
        />
      ) : (
        <>
          <EventTitle>{name}</EventTitle>
          <EventActions
            status={status}
            onSubmitContactAll={handleContactAllSubmit}
            onSubmitBookUser={handleBookUser}
            eventInstance={eventInstance}
          />
        </>
      )}
    </EventHeaderContainer>
  );
};

const EventTitle = styled.p`
  font-size: 25px;
  line-height: 31px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const EventHeaderContainer = styled.div`
  height: 116px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    height: 82px;
  }
`;
