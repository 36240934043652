import React, { FunctionComponent } from 'react';
import { Header, Footer } from '@/components';
import { useAuth } from '@/contexts';
import { identifyUser } from '@/analytics';
import { updateIntercomUser } from '@/services/intercom';
import { useWindowResize } from '@/hooks';

interface LayoutPropsI {
  children?: React.ReactNode;
}

export const Layout: FunctionComponent<LayoutPropsI> = ({ children }) => {
  const { session } = useAuth();
  const { isMobileSize } = useWindowResize();
  const path = window.location.pathname;

  const fullScreen =
    path.includes('/agenda/ingresar-reserva') ||
    (isMobileSize && path.includes('/ajustes/medios-de-pago/mercadopago')) ||
    (isMobileSize && path.includes('/ajustes/medios-de-pago/efectivo')) ||
    (isMobileSize && path.includes('/ajustes/medios-de-pago/transferencia'));

  if (session) {
    identifyUser(session);
    updateIntercomUser(session, isMobileSize);
  }

  return (
    <>
      {fullScreen ? null : <Header />}
      <main className="notranslate flex-1" id="main">
        <div className="h-full flex flex-col">{children}</div>
      </main>
      {fullScreen ? null : <Footer />}
    </>
  );
};
