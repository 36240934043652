import React from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef } from '@tanstack/react-table';

type TableProps<T extends object> = {
  columns: ColumnDef<T, any>[];
  data: T[];
  onRowClick?: (row: T) => void;
  maxHeight?: number;
  paginationEnabled?: boolean;
  onEnd?: () => void;
  isLoading?: boolean;
};

const TableV2 = <T extends object>({
  columns,
  data,
  onRowClick,
  maxHeight,
  paginationEnabled = false,
  onEnd,
  isLoading
}: TableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const handleRowClick = (row: any) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // We've reached the bottom
          if (onEnd) onEnd();
        }
      }
    };
    if (paginationEnabled && wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [paginationEnabled, onEnd]);

  React.useEffect(() => {
    const disableNativeDrag = (event: DragEvent) => {
      event.preventDefault();
    };

    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener('dragstart', disableNativeDrag);
      wrapper.addEventListener('drop', disableNativeDrag);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener('dragstart', disableNativeDrag);
        wrapper.removeEventListener('drop', disableNativeDrag);
      }
    };
  }, []);

  const SkeletonLoaderRow = ({ columnCount }: { columnCount: number }) => (
    <tr className="w-full divide-x divide-gray-200 h-14">
      {Array.from({ length: columnCount }).map((_, index) => (
        <SkeletonLoaderCell key={index} />
      ))}
    </tr>
  );

  const SkeletonLoaderCell = () => (
    <td className="px-4 w-auto bg-gray-100">
      <SkeletonLoaderDiv />
    </td>
  );

  const SkeletonLoaderDiv = () => <div className="h-4 bg-gray-200 animate-pulse w-full" />;

  return (
    <div
      ref={wrapperRef}
      className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full"
      style={{ maxHeight: `${maxHeight}px` }}
    >
      <table className="min-w-full divide-y divide-gray-200 bg-white">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="divide-x divide-gray-200">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="py-4 px-2 border-t text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                handleRowClick(row);
              }}
              className="cursor-pointer hover:bg-gray-100 transition-colors duration-300 divide-x divide-gray-200 h-14"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 whitespace-nowrap text-sm text-gray-500">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {isLoading && <SkeletonLoaderRow columnCount={columns.length} />}
        </tbody>
      </table>
    </div>
  );
};

export default TableV2;
