import React from 'react';
import { Column, Row } from '@/components';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowResize } from '@/hooks';
import { Dialog, DialogContentHalfBottom, DialogHeader, DialogTrigger } from '../Dialog';
import classNames from 'classnames';

export type ActionsType = {
  label: string;
  icon?: IconDefinition;
  onClick: () => void;
  selected?: boolean;
};

type ActionsMenuProps = {
  children?: React.ReactNode;
  actions: ActionsType[];
  title?: string;
  onClose?: () => void;
  show?: boolean;
  x?: number;
  y?: number;
};

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  children,
  actions,
  title,
  onClose,
  show,
  x,
  y
}) => {
  const { isMobileSize } = useWindowResize();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(!!show);
  }, [show]);

  const onOpenChange = (open: boolean) => {
    if (onClose) onClose();
    setIsOpen(open);
  };

  if (isMobileSize)
    return (
      <Dialog {...(show ? { open: show } : { open: isOpen })} onOpenChange={(open) => onOpenChange(open)}>
        {children ? (
          <DialogTrigger asChild>{children}</DialogTrigger>
        ) : (
          <DialogTrigger style={{ position: 'absolute', top: `${y + 'px'}`, left: `${x + 'px'}` }} />
        )}
        <DialogContentHalfBottom>
          {title && (
            <DialogHeader className="border-b pb-6">
              <span className="truncate">{title}</span>
            </DialogHeader>
          )}
          <Column gap={22} className={classNames({ 'pt-6': title })}>
            {actions.map((action) => {
              const isRed = action.label === 'Eliminar' || action.label === 'Cancelar';
              const isSelected = action.selected;
              return (
                <button
                  key={action.label}
                  onClick={(e) => {
                    action.onClick();
                    e.stopPropagation();
                    onOpenChange(false);
                  }}
                >
                  <Row
                    gap={24}
                    align="center"
                    className={classNames('text-xl', { 'text-red-600': isRed, 'text-blue': isSelected })}
                  >
                    {action.icon && <FontAwesomeIcon fixedWidth icon={action.icon} size="lg" />}
                    <span className="font-medium">{action.label}</span>
                  </Row>
                </button>
              );
            })}
          </Column>
        </DialogContentHalfBottom>
      </Dialog>
    );

  return (
    <DropdownMenu {...(show && { open: isOpen })} onOpenChange={onOpenChange}>
      {children ? (
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger style={{ position: 'absolute', top: `${y + 'px'}`, left: `${x + 'px'}` }} />
      )}
      <DropdownMenuContent>
        {actions.map((action) => {
          const isSelected = action.selected;
          return (
            <DropdownMenuItem
              key={action.label}
              onClick={(e) => {
                action.onClick();
                e.stopPropagation();
              }}
              className={classNames('cursor-pointer', { 'text-blue': isSelected })}
            >
              <Row gap={10} align="center">
                {action.icon && <FontAwesomeIcon fixedWidth icon={action.icon} />}
                {action.label}
              </Row>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
