import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { useClient } from '@/hooks';
import { BookingStatusE, PaymentTypeE, VendorClientsBaseI } from '@/types/cyclone/models';
import { useMutation, useQuery } from 'react-query';
import { scrollBarTW } from '@/components/App';
import { ActionsMenu, Column, Grid, ResponsiveContainer, Row } from '@/components';
import dayjs from 'dayjs';
import { EmptyObject, ErrorI, PutClientI } from '@/types/cyclone/requests';
import { toast } from 'react-toastify';
import { getBookingDescription } from '@/utils/bookings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faEllipsisVertical,
  faFileCirclePlus,
  faPenToSquare,
  faTrashAlt,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';
import { ClientModal } from './components/ClientModal';
import useScreenSize from '@/hooks/useScreenSize';
import { InputV2 } from '@/components/Input';
import classNames from 'classnames';
import { useConfirmation } from '@/contexts';
import { Link } from 'react-router-dom';
import { trackGenericEvent } from '@/analytics';
import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import useLog from '@/hooks/useLog';
import Skeleton from './components/Skeleton';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
interface History {
  title: string;
  prefix: string;
  createdAt: string | Date;
  originalCreatedAt?: string | Date;
  type: 'booking' | 'entry';
  entry_id?: number;
  content: string;
}

export const Client: FunctionComponent = () => {
  useMixpanelTrackPage('Client Details');
  const { id } = useParams();
  const { client } = useClient();
  const { logAndNotify } = useLog();
  const { isMobile } = useScreenSize();
  const { confirm } = useConfirmation();

  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [entryId, setEntryId] = React.useState<number | null>(null);

  const [isNewEntry, setIsNewEntry] = React.useState(false);
  const [isNewEntryDisabled, setIsNewEntryDisabled] = React.useState(false);
  const [showClientModal, setShowClientModal] = React.useState(false);

  const {
    data: vendorClient,
    isLoading,
    refetch: refetchClients
  } = useQuery(
    ['client_base', id],
    async () =>
      await client<VendorClientsBaseI>(`me/vendor/client_base/${id}`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const mutationAddEntry = useMutation<EmptyObject, ErrorI, { title: string; description: string }>(
    (data) =>
      client(`me/vendor/client_base/${id}/entry`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Entrada creada con éxito');
        refetchClients();
        cleanUpEntry();
      },
      onError: (error: any) => {
        logAndNotify(`Error al crear una entrada a un cliente - ${error}`);
        toast.error(error.message);
      }
    }
  );

  const mutationEditEntry = useMutation<
    EmptyObject,
    ErrorI,
    { title: string; description: string; entry_id: number }
  >(
    (data) =>
      client(`me/vendor/client_base/${id}/entry/${data.entry_id}`, 'PUT', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Entrada creada con éxito');
        refetchClients();
        cleanUpEntry();
      },
      onError: (error: any) => {
        logAndNotify(`Error al editar una entrada a un cliente - ${error}`);
        toast.error(error.message);
      }
    }
  );

  const mutationDeleteEntry = useMutation<EmptyObject, ErrorI, { entry_id: number }>(
    (data) =>
      client(`me/vendor/client_base/${id}/entry/${data.entry_id}`, 'DELETE', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Entrada eliminada con éxito');
        refetchClients();
        cleanUpEntry();
      },
      onError: (error: any) => {
        logAndNotify(`Error al eliminar una entrada a un cliente - ${error}`);
        toast.error(error.message);
      }
    }
  );

  const mutationUpdateClientBase = useMutation<EmptyObject, ErrorI, PutClientI>(
    (data) =>
      client(`me/vendor/client_base/${id}`, 'PUT', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Cliente actualizado con éxito');
        refetchClients();
      },
      onError: (e: any) => {
        if (e.code && e.code === 'UC-001') {
          toast.error('El mail ya está registrado, por favor, usar otro');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const phone = vendorClient?.user?.phone ? vendorClient.user.phone : '-';
  const dob = vendorClient?.user?.dob ? dayjs(vendorClient.user.dob).format('DD/MM/YYYY') : '-';
  const notes = vendorClient?.notes ? vendorClient.notes : '-';
  const address = vendorClient?.address ? vendorClient.address : '-';
  const email = vendorClient?.user?.email ? vendorClient.user.email : '-';

  const _professionals = vendorClient?.user?.bookings?.map((booking) => {
    const { vendor } = booking;
    if (!vendor) return null;
    return `${vendor.user.first_name} ${vendor.user.last_name}`;
  });
  const professionals = [...new Set(_professionals)];

  const cleanUpEntry = () => {
    setTitle('');
    setDescription('');
    setEntryId(null);
    setIsNewEntry(false);
    setIsNewEntryDisabled(false);
  };

  const updateClientBase = (data: PutClientI) => {
    mutationUpdateClientBase.mutate(data);
  };

  const handleDeleteEntry = (id: number) => {
    trackGenericEvent('Button Delete Entry Clicked');
    confirm({
      status: 'danger',
      content: '¿Queres eliminar esta entrada?',
      confirmText: 'Si, eliminar',
      onConfirm: () => {
        mutationDeleteEntry.mutate({ entry_id: id });
      }
    });
  };

  const addEntry = () => {
    trackGenericEvent('Button Add Entry Clicked');

    if (!title) return toast.error('El título es requerido');

    if (entryId) {
      mutationEditEntry.mutate({ title, description, entry_id: entryId });
    } else {
      mutationAddEntry.mutate({ title, description });
    }
  };

  const getPaymentTypeTitle = (paymentType?: PaymentTypeE) => {
    if (paymentType === PaymentTypeE.VENDOR_INVITE) return 'Reserva ingresada';
    else return 'Reserva confirmada';
  };

  const getHistory = () => {
    const confirmedBookings =
      vendorClient?.user?.bookings?.filter((booking) => booking.status === BookingStatusE.CONFIRMED) || [];
    const bookingsHistory: History[] = confirmedBookings.map((booking) => {
      const payment = booking.payments![0];
      const paymentTitle = getPaymentTypeTitle(payment.type);
      const assetTitle = getBookingDescription(booking);

      return {
        title: assetTitle,
        prefix: paymentTitle,
        originalCreatedAt: booking.created_at,
        createdAt:
          payment.type === PaymentTypeE.VENDOR_INVITE
            ? `${dayjs(booking.created_at).format('DD MMMM YYYY HH:mm')}, por ${
                booking.vendor.user.first_name
              } ${booking.vendor.user.last_name}`
            : `${dayjs(booking.created_at).format('DD MMMM YYYY HH:mm')}`,
        type: 'booking',
        content: ''
      };
    });

    const entriesHistory: History[] =
      vendorClient?.vendor_clients_base_history?.map((entry) => {
        const title = `${entry.title}`;
        const createdAt = `${dayjs(entry.created_at).format('DD MMMM YYYY HH:mm')}, por ${
          entry.vendor.user.first_name
        } ${entry.vendor.user.last_name}`;
        const content = `${entry.description}`;

        return {
          title: title,
          prefix: 'Entrada',
          content: content,
          originalCreatedAt: entry.created_at,
          createdAt: createdAt,
          type: 'entry',
          entry_id: entry.id
        };
      }) || [];

    const history = [...bookingsHistory, ...entriesHistory];

    return history.sort((a, b) => {
      const dateA = new Date(a.originalCreatedAt as Date);
      const dateB = new Date(b.originalCreatedAt as Date);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const history = getHistory();

  const isDisabled = !title;

  const getActions = (entry: History) => {
    const actions = [];
    actions.push(
      {
        label: 'Ver',
        onClick: () => {
          trackGenericEvent('Button View Entry Clicked');

          setTitle(entry.title);
          setDescription(entry.content);
          setIsNewEntry(true);
          setIsNewEntryDisabled(true);
        },
        icon: faEye
      },
      {
        label: 'Editar',
        onClick: () => {
          trackGenericEvent('Button Edit Entry Clicked');

          setTitle(entry.title);
          setDescription(entry.content);
          setIsNewEntry(true);
          setEntryId(entry.entry_id!);
        },
        icon: faEdit
      },
      {
        label: 'Eliminar',
        onClick: () => {
          handleDeleteEntry(entry.entry_id!);
        },
        icon: faTrashAlt
      }
    );
    return actions;
  };

  const Timeline = ({ entries }: { entries: History[] }) => {
    return (
      <Column className={`relative gap-5 max-h-[300px] overflow-y-scroll ${scrollBarTW}`}>
        {entries.map((entry, i) => (
          <Row key={i} className="flex items-center justify-between">
            <div className="flex items-center justify-center">
              <div className="w-4 h-4 rounded-full bg-gray-300" />
              <Column className="ml-4">
                <h3 className="text-base">{`${entry.prefix}: ${entry.title}`}</h3>
                <p className="text-sm text-[#ACACAC]">{entry.createdAt as string}</p>
              </Column>
            </div>
            {entry.type === 'entry' ? (
              <ActionsMenu actions={getActions(entry)}>
                <Row
                  justify="center"
                  align="center"
                  className="rounded-full bg-[#FBFBFB] p-2 w-8 h-8 cursor-pointer shadow-[0px_3px_4px_0px_#0000001F]"
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} size="lg" color="#868686" />
                </Row>
              </ActionsMenu>
            ) : null}
          </Row>
        ))}

        <div className="absolute top-0 left-[6px] h-full w-0.5 bg-gray-300" style={{ marginLeft: '1.5px' }} />
      </Column>
    );
  };

  return (
    <div>
      <section className="sticky top-[48px] pt-2 bg-white z-10">
        <ResponsiveContainer>
          <Row
            align="center"
            justify="space-between"
            className="bg-white pb-4 border-b border-[#DACCE0] w-full"
          >
            <Column gap={8} className="w-full sm:w-3/5">
              {isMobile ? (
                <Grid as="div" columns={3} className="w-full items-center place-self-start">
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    color="#868686"
                    size="lg"
                    onClick={cleanUpEntry}
                    className={classNames({ invisible: !isNewEntry, visible: isNewEntry })}
                  />
                  <h1 className="text-lg font-medium">Mis clientes</h1>
                  <button
                    className={classNames('text-[#0072FB]font-medium disabled:opacity-50 place-self-end', {
                      invisible: !isNewEntry,
                      visible: isNewEntry
                    })}
                    onClick={addEntry}
                    disabled={isDisabled}
                  >
                    Guardar
                  </button>
                </Grid>
              ) : (
                <>
                  <Row align="baseline" gap={10}>
                    <Link to="/clientes" className="cursor-pointer font-normal text-xl sm:text-3xl">
                      Clientes
                    </Link>
                    <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
                    <h1 className="font-normal text-xl sm:text-3xl">
                      {vendorClient?.user?.first_name} {vendorClient?.user?.last_name}
                    </h1>
                  </Row>
                  <p className="text-sm text-[#828282]">Consultá y gestioná la información de este cliente</p>
                </>
              )}
            </Column>
            {isNewEntry && !isMobile && (
              <Row className="gap-5" align="center">
                <span className="text-blue cursor-pointer" onClick={cleanUpEntry}>
                  Volver
                </span>
                <button
                  className="bg-[#0072FB] text-white rounded-3xl px-7 py-2 font-medium sm:hover:bg-[#0062D6] disabled:opacity-50"
                  onClick={addEntry}
                  disabled={isDisabled}
                >
                  Guardar
                </button>
              </Row>
            )}
          </Row>
        </ResponsiveContainer>
      </section>

      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="sm:bg-[#FAFAFA] h-full">
          <ResponsiveContainer>
            <div className="grid grid-cols-6 !pt-5 !pb-20 gap-12">
              {(!isNewEntry || !isMobile) && (
                <Column className="col-span-6 md:col-span-2 bg-white md:border md:border-[#F0F0F0] md:rounded-xl md:px-8 py-4 md:py-9 md:gap-5">
                  <Row justify="space-between" className="w-full" align="center">
                    <span className="text-2xl font-bold">
                      {vendorClient?.user?.first_name} {vendorClient?.user?.last_name}
                    </span>
                    {isMobile ? (
                      <button className="text-[#0072FB] font-medium" onClick={() => setShowClientModal(true)}>
                        Editar
                      </button>
                    ) : (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        color="#0072FB"
                        className="cursor-pointer"
                        onClick={() => setShowClientModal(true)}
                      />
                    )}
                  </Row>
                  <span className="font-bold underline py-4 md:py-0">Datos personales</span>
                  <Grid as="div" columns={3} gap={20} className="w-full">
                    <span className="text-sm text-[#828282]">Email</span>
                    <span className="col-span-2 text-sm">{email}</span>
                    <span className="text-sm text-[#828282]">Teléfono</span>
                    <span className="col-span-2 text-sm text-[#0072FB]">{phone}</span>
                    <span className="text-sm text-[#828282]">Fecha de nac.</span>
                    <span className="col-span-2 text-sm text-[#0072FB]">
                      {dayjs(dob).isValid() ? dayjs(dob).format('DD/MM/YYYY') : '-'}
                    </span>
                    <span className="text-sm text-[#828282]">Dirección</span>
                    <span className="col-span-2 text-sm text-[#0072FB]">{address}</span>
                    <span className="text-sm text-[#828282]">Cliente desde</span>
                    <span className="col-span-2 text-sm">
                      {dayjs(vendorClient?.created_at).format('DD/MM/YYYY')}
                    </span>
                    <span className="text-sm text-[#828282]">Otros datos</span>
                    <div
                      className={`p-1 col-span-2 h-16 rounded-md bg-[#FAFAFA] overflow-y-scroll ${scrollBarTW}`}
                    >
                      <span className="col-span-2 text-sm text-[#0072FB]">{notes}</span>
                    </div>
                    <span className="text-sm text-[#828282]">Profesionales</span>
                    <span className="text-sm col-span-2">
                      {professionals.length > 0 ? (
                        professionals.map((professional, i) => (
                          <span key={i} className="badge badge-outline">
                            {professional}
                          </span>
                        ))
                      ) : (
                        <span className="col-span-2 text-sm">-</span>
                      )}
                    </span>
                  </Grid>
                </Column>
              )}
              <Column className="col-span-6 md:col-span-4 bg-white md:border md:border-[#F0F0F0] md:rounded-xl  md:px-8 md:py-9 md:gap-5">
                {isNewEntry ? (
                  <Column gap={30}>
                    <Row gap={10} className="w-full">
                      <div className="w-[80%] sm:w-full">
                        <InputV2
                          id="title"
                          type="text"
                          floatLabel
                          placeholder="Título de la entrada *"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          disabled={isNewEntryDisabled}
                        />
                      </div>
                      <button className="flex items-center justify-center aspect-square bg-[#FAFAFA] rounded-lg border border-[#F0F0F0]">
                        <FontAwesomeIcon icon={faFileCirclePlus} color="#0072FB" />
                      </button>
                    </Row>
                    <RichTextEditor
                      readOnly={isNewEntryDisabled}
                      placeholder="Notas"
                      content={description}
                      onChange={(e) => setDescription(e)}
                      limit={350}
                    />
                  </Column>
                ) : (
                  <>
                    <Row justify="space-between" align="center" className="mb-4">
                      <span className="text-xl md:text-2xl font-bold">Actividad reciente</span>
                      <button
                        className="text-[#0072FB] md:bg-[#0072FB] md:text-white rounded-3xl px-4 md:px-7 py-2 font-medium sm:hover:bg-[#0062D6]"
                        onClick={() => setIsNewEntry(true)}
                      >
                        Nueva entrada
                      </button>
                    </Row>
                    <Timeline entries={history} />
                  </>
                )}
              </Column>
            </div>
          </ResponsiveContainer>
        </div>
      )}
      {showClientModal && vendorClient && (
        <ClientModal
          show={showClientModal}
          setShow={setShowClientModal}
          clientBase={vendorClient}
          onSubmit={updateClientBase}
        />
      )}
    </div>
  );
};
