import { PostBlockedHoursI } from '@/types/cyclone/requests';
import React, { FunctionComponent } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { BlockHours } from './BlockHours';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '@/contexts';

dayjs.extend(utc);
dayjs.extend(timezone);

type BlockHoursModalProps = {
  show: boolean;
  onClose: () => void;
  onCreate: (data: PostBlockedHoursI) => void;
};

export type BlockHoursFormState = {
  startAt: Date;
  endAt: Date;
  startTime: string;
  endTime: string;
};

const FORMAT_STRING = 'YYYY-MM-DDTHH:mm:ss';

export const BlockHoursModal: FunctionComponent<BlockHoursModalProps> = ({ show, onClose, onCreate }) => {
  const { control, handleSubmit, watch, setValue } = useForm<BlockHoursFormState>({
    defaultValues: {
      startAt: new Date(),
      endAt: new Date(),
      startTime: dayjs(new Date()).format('HH:mm'),
      endTime: dayjs(new Date()).add(1, 'hour').format('HH:mm')
    }
  });
  const { session } = useAuth();

  const timezone = session?.vendor?.timezone || 'America/Argentina/Buenos_Aires';

  const [dateChange, setDateChange] = React.useState(false);
  const [timeChange, setTimeChange] = React.useState(false);

  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const startTime = watch('startTime');
  const endTime = watch('endTime');

  React.useEffect(() => {
    if (dateChange) {
      setTimeout(() => {
        setDateChange(false);
      }, 1500);
    }
    if (timeChange) {
      setTimeout(() => {
        setTimeChange(false);
      }, 1500);
    }
  }, [dateChange, timeChange]);

  React.useEffect(() => {
    if (startAt && endAt && startTime && endTime) {
      const combinedStart = dayjs.tz(`${dayjs(startAt).format('YYYY-MM-DD')}T${startTime}`, timezone);
      const combinedEnd = dayjs.tz(`${dayjs(endAt).format('YYYY-MM-DD')}T${endTime}`, timezone);

      const startDate = dayjs(startAt).startOf('day');
      const endDate = dayjs(endAt).startOf('day');

      const [startHour, startMinute] = startTime.split(':').map(Number);
      const [endHour, endMinute] = endTime.split(':').map(Number);

      if (startDate.isAfter(endDate)) {
        if (startHour < endHour || (startHour === endHour && startMinute < endMinute)) {
          setValue('endAt', dayjs(startAt).toDate());
        } else {
          setValue('endAt', dayjs(startAt).add(1, 'day').toDate());
        }
        setDateChange(true);
      } else if (combinedStart.isSame(combinedEnd) && startTime === endTime) {
        setValue('endTime', combinedEnd.add(30, 'minutes').format('HH:mm'));
        setTimeChange(true);
      } else if (combinedEnd.isBefore(combinedStart)) {
        if (startDate.isSame(endDate) && startHour > endHour) {
          setValue('endAt', dayjs(startAt).add(1, 'day').toDate());
          setDateChange(true);
        }
      }
    }
  }, [endAt, endTime, startAt, startTime, timezone, setValue]);

  const fullSubmit = () => {
    onClose();
    handleSubmit(onSubmit)();
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const combinedStart = dayjs
      .tz(`${dayjs(data.startAt).format('YYYY-MM-DD')}T${data.startTime}`, timezone)
      .format(FORMAT_STRING);
    const combinedEnd = dayjs
      .tz(`${dayjs(data.endAt).format('YYYY-MM-DD')}T${data.endTime}`, timezone)
      .format(FORMAT_STRING);

    onCreate({
      start_at: combinedStart,
      end_at: combinedEnd,
      start_time: data.startTime,
      end_time: data.endTime
    });
  };

  return (
    <BlockHours
      show={show}
      onClose={onClose}
      control={control}
      onSubmit={fullSubmit}
      startAt={startAt}
      dateChange={dateChange}
      timeChange={timeChange}
    />
  );
};
