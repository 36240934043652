// components/Autocomplete.tsx
import Downshift from 'downshift';

interface AutocompleteProps {
  options: Array<{ label: string; value: string }>;
  value: string | null;
  onChange: (selectedItem: { label: string; value: string } | null) => void;
  placeholder: string;
  helpText?: string;
}

export const InputAutocomplete: React.FC<AutocompleteProps> = ({
  options,
  value,
  onChange,
  placeholder,
  helpText
}) => {
  const selectedItem = value ? options.find((option) => option.value === value) : null;

  return (
    <>
      <Downshift
        selectedItem={selectedItem}
        onChange={onChange}
        itemToString={(item) => (item ? item.label : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          inputValue,
          selectedItem
        }) => {
          const filteredOptions = options.filter((item) =>
            // @ts-ignore
            item.label.toLowerCase().includes(inputValue.toLowerCase())
          );

          return (
            <div className="relative w-full">
              <input
                {...getInputProps({
                  placeholder: placeholder
                })}
                style={{ border: '1px solid rgba(0, 0, 0, 0.36)' }}
                className="rounded py-3 px-4 w-full text-black bg-white focus:outline-none focus:border-blue-500"
              />
              <ul
                {...getMenuProps({
                  className: 'absolute z-10 w-full bg-white shadow-md rounded mt-2 overflow-y-auto max-h-64'
                })}
              >
                {isOpen &&
                  filteredOptions.map((item, index) => (
                    <li
                      key={index}
                      {...getItemProps({
                        item,
                        className: `p-2 last:mb-0 ${
                          highlightedIndex === index ? 'bg-gray-200' : 'bg-white'
                        } ${selectedItem === item ? 'font-bold' : 'font-normal'}`
                      })}
                    >
                      {item.label}
                    </li>
                  ))}
              </ul>
            </div>
          );
        }}
      </Downshift>
      {helpText && <p className="text-gray-500 text-xs mt-1 ml-4">{helpText}</p>}
    </>
  );
};
