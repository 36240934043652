import { ErrorI, GetServicesI, PostAssignBookingEventInstanceI } from '@/types/cyclone/requests';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { ListServices, ClientForm, ListVenues, DateAndPayment } from './components';
import { ServiceI, ServiceStatusE } from '@/types/cyclone/models';
import { EmptyObject, FieldValues } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useClient } from '@/hooks';
import { toast } from 'react-toastify';
import { useAuth, useConfirmation } from '@/contexts';
import { Column, ResponsiveContainer, Row } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import useLog from '@/hooks/useLog';

export type preInfoType = {
  services: 'all' | 'onDemand';
  date: Date | null;
  time?: string;
  service?: ServiceI | null;
};

export const ActionReservation: FunctionComponent = () => {
  useMixpanelTrackPage('Book Reservations');
  const { session } = useAuth();
  const { client } = useClient();
  const { confirm } = useConfirmation();
  const navigate = useNavigate();
  const { logAndNotify } = useLog();

  const isStaff = session?.is_staff;

  const [itemSelected, setItemSelected] = useState<ServiceI | undefined>(undefined);
  const [venueIdSelected, setVenueIdSelected] = useState<string | undefined>(
    !isStaff ? undefined : session?.vendor?.id.toString()
  );
  const [step, setStep] = useState(0);
  const [preInfo, setPreInfo] = useState<preInfoType | undefined>(undefined);
  const [request, setRequest] = useState<Partial<PostAssignBookingEventInstanceI> | undefined>(undefined);
  const [saveInfo, setSaveInfo] = useState<FieldValues | undefined>(undefined);

  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const vendorId = params.get('vendor');
  const servicesType = params.get('services');
  const serviceId = params.get('service');
  const dateString = params.get('date');

  const { data: _services } = useQuery(
    ['services'],
    async () =>
      await client<GetServicesI>(`me/vendor/services`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (data.length > 0) {
          if (servicesType || dateString) {
            const date = dateString ? new Date(dateString) : null;
            const time = typeof date !== null ? dayjs(date).format('HH:mm') : undefined;
            if (serviceId) {
              const findService = data.find((service) => service.id === +serviceId);
              setItemSelected(findService);
              setStep(session?.is_venue ? 1 : 2);
              setPreInfo({
                services: servicesType as 'all' | 'onDemand',
                service: findService,
                date,
                time
              });
            }
            setPreInfo({
              services: servicesType as 'all' | 'onDemand',
              date,
              time
            });
          } else setPreInfo(undefined);
        }
      }
    }
  );

  const services = !isStaff
    ? _services || []
    : _services?.filter((service) =>
        service.multi_vendors.some((v) => v.vendor_id === session?.vendor?.id)
      ) || [];

  // WARNING: what happens if services is empty ?

  const filteredServices = () => {
    if (preInfo?.services === 'onDemand') {
      let filteredService = services;

      if (session?.vendor?.id === 2529) {
        filteredService = services.filter((service) => service.is_on_demand);
      } else {
        filteredService = services
          .filter((service) => service.is_on_demand)
          .filter((s) => s.status === ServiceStatusE.ACTIVE);
      }

      if (vendorId) {
        // for services where multiple vendors can provide the service, we need to filter by vendorId
        return filteredService.filter((s) => {
          if (s.multi_vendors.length > 0) {
            return s.multi_vendors.some((v) => v.vendor_id === +vendorId);
          } else {
            return s.vendor_id === +vendorId;
          }
        });
      } else return filteredService;
    } else {
      if (session?.vendor?.id === 2529) {
        return services;
      } else return services.filter((s) => s.status === ServiceStatusE.ACTIVE);
    }
  };

  const mutationValidateBookUser = useMutation<
    { is_valid: boolean },
    ErrorI,
    PostAssignBookingEventInstanceI
  >(
    (data) =>
      client(`me/vendor/blocked_hours/validate`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: (result, data) => {
        if (!result.is_valid) {
          confirm({
            title: '¿Querés continuar con el ingreso de reserva?',
            content: 'Ya hay una reserva en este horario. ¿Querés ingresar la nueva reserva?',
            confirmText: 'Si, continuar',
            onConfirm: () => {
              mutationBookUser.mutate(data);
            }
          });
        } else mutationBookUser.mutate(data);
      }
    }
  );

  const mutationBookUser = useMutation<EmptyObject, ErrorI, PostAssignBookingEventInstanceI>(
    (data) =>
      client(`bookings/event_instances/book`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: (data, variables) => {
        toast.success('Se ha reservado al cliente exitosamente');
        setPreInfo(undefined);
        const searchParams = new URLSearchParams();
        if (variables.vendor_id) searchParams.append('vendor', variables.vendor_id);
        searchParams.append('date', variables.start_at);

        navigate(`/agenda?${searchParams.toString()}`);
      },
      onError: (e) => {
        logAndNotify(`Error al reservar un cliente - ${e}`);
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
        navigate('/agenda');
      }
    }
  );

  const handleOnFormSave = (data: Partial<PostAssignBookingEventInstanceI>) => {
    setRequest(data);
    setStep(3);
    setSaveInfo(data);
  };
  const handleOnSubmit = (data: Partial<PostAssignBookingEventInstanceI>) => {
    mutationValidateBookUser.mutate({ ...data, ...request } as any);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <h1 className="text-base sm:text-2xl pb-4">1. Seleccioná un servicio para esta reserva:</h1>
            <ListServices
              services={filteredServices()}
              nextStep={() => {
                if (isStaff) {
                  setStep(2);
                } else {
                  setStep(session?.is_venue ? 1 : 2);
                }
              }}
              setItemSelected={setItemSelected}
            />
          </>
        );
      case 1:
        return (
          itemSelected &&
          session?.is_venue && (
            <>
              <h1 className="text-base sm:text-2xl pb-4">2. ¿Quién brindará el servicio?</h1>
              <ListVenues
                service={itemSelected}
                nextStep={() => setStep(2)}
                setVenueIdSelected={setVenueIdSelected}
              />
            </>
          )
        );
      case 2:
        return (
          itemSelected && (
            <>
              <h1 className="text-base sm:text-2xl">
                {session?.is_venue ? `3.` : '2.'} Completá los datos del cliente
              </h1>
              <ClientForm
                service={itemSelected}
                venueId={venueIdSelected}
                onSave={handleOnFormSave}
                isLoading={mutationBookUser.isLoading || mutationValidateBookUser.isLoading}
                saveInfo={saveInfo}
              />
            </>
          )
        );
      case 3:
        return (
          itemSelected && (
            <>
              <h1 className="text-base sm:text-2xl">4. Fecha, horario y pago</h1>
              <DateAndPayment
                onSubmit={handleOnSubmit}
                service={itemSelected}
                venueId={venueIdSelected}
                preInfo={preInfo}
                isLoading={mutationBookUser.isLoading || mutationValidateBookUser.isLoading}
              />
            </>
          )
        );
      default:
        return null;
    }
  };

  const handleBackButton = () => {
    if ((step === 2 && !session?.is_venue) || (step === 2 && isStaff)) {
      params.delete('service');
      navigate(`/agenda/ingresar-reserva?${params.toString()}`, {
        replace: true
      });
      setStep(0);
      return;
    } else if (step === 1) {
      params.delete('service');
      navigate(`/agenda/ingresar-reserva?${params.toString()}`, {
        replace: true
      });
      setStep(0);
      return;
    } else return setStep(step - 1);
  };

  return (
    <section>
      <div className="w-full">
        <ResponsiveContainer>
          <Column className="border-b py-4">
            <Row align="baseline" className="gap-2 sm:gap-4 text-lg sm:text-3xl">
              <p className="cursor-pointer" onClick={() => navigate('/agenda')}>
                Agenda
              </p>
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
              <p>Ingresar reserva a un cliente</p>
            </Row>
            <p className="text-sm sm:text-base text-[#828282]">Gestioná los turnos y disponibilidad</p>
          </Column>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer className="py-4">
        <Column justify="center" align="center" className="w-full">
          <div className="w-full max-w-xl border rounded-xl bg-white p-4">
            {renderStep()}
            {step > 0 && (
              <button
                onClick={handleBackButton}
                className="text-blue hover:underline font-medium flex justify-center w-full"
              >
                Volver
              </button>
            )}
          </div>
        </Column>
      </ResponsiveContainer>
    </section>
  );
};
