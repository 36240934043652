import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { signinBanner } from '@/static/images';
import { useSessionStorage } from '@/hooks';
import usePageTracking from '@/hooks/usePageTracking';
import * as yup from 'yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Column, Grid, Input, Row, SocialButton } from '@/components';
import { Button } from '@/components/Button';
import { authorize, register } from '@/auth';
import useScreenSize from '@/hooks/useScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Mail inválido')
    .test('is-valid-suffix', 'El correo electrónico tiene un sufijo inválido', function (value) {
      if (!value) return true;
      const validSuffixes = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'live'];
      const emailParts = value.split('@');
      if (emailParts.length !== 2) return false;
      const suffix = emailParts[1].split('.')[0];

      const similarSuffixes = validSuffixes.filter((validSuffix) => {
        const intersection = new Set([...validSuffix].filter((char) => suffix.includes(char)));
        const union = new Set([...validSuffix, ...suffix]);
        const similarity = intersection.size / union.size;

        return similarity >= 0.7;
      });

      if (similarSuffixes.length === 0) {
        return true;
      }
      return validSuffixes.includes(suffix);
    })
    .required('Campo requerido'),
  first_name: yup.string().required('Campo requerido'),
  last_name: yup.string().required('Campo requerido'),
  password: yup.string().min(8, 'Al menos 8 caracteres').required('Campo requerido')
});

const API_URL = import.meta.env.VITE_CYCLONE_API_URL || 'INSERT_CYCLONE_API_URL';

export const SignUp: FunctionComponent = () => {
  usePageTracking();
  const { setValue } = useSessionStorage('invitedBy', {});

  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const [username, setUsername] = useState<string | null>(null);

  const usernameParam = new URLSearchParams(location.search).get('username') as string;

  // force push
  useEffect(() => {
    if (usernameParam) {
      // use fetch api to get the vendor by username
      const getVendorByUsername = async () => {
        // if vendor exists, set the value in session storage
        // if it throws an error, do nothing
        try {
          const vendor = await fetch(`${API_URL}/vendors/${usernameParam}`);
          if (vendor.ok) {
            setValue(usernameParam);
            setUsername(usernameParam);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getVendorByUsername();
    }
  }, [usernameParam]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ resolver: yupResolver(schema), mode: 'all' });
  const [loading, setLoading] = useState(false);

  const errorHandler = (err: auth0.Auth0Error | null) => {
    setLoading(false);
    if (err) {
      toast.error('El email o la contraseña que ingresaste son incorrectos, por favor intentalo nuevamente.');
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    register(data.first_name, data.last_name, data.email, data.password, errorHandler);
    setLoading(true);
  };

  const referralBanner = username !== null;

  return (
    <>
      {referralBanner && (
        <Row className="bg-blue text-white w-full py-4 font-light" justify="center" align="center">
          <span className="text-center">
            Beneficio para referidos de <b>@{username}</b>: descuento de $1.000 en tu Plan Mensual.
          </span>
        </Row>
      )}
      <Row>
        <div className="flex-col font-medium pt-8 items-center bg-[#fafafa] hidden md:block md:w-3/5 min-h-dvh max-h-dvh lg:flex">
          <ImageContainer isMobileSize={isMobile} image={signinBanner}></ImageContainer>
          <p className="text-lg text-center">
            Digitalizá tu negocio, optimizá tu tiempo y escalá tus ingresos.
          </p>
        </div>
        <div
          className="w-full md:w-2/5 min-h-dvh max-h-dvh"
          style={{
            boxShadow: '0px 4px 23px 0px rgba(0, 0, 0, 0.11)'
          }}
        >
          <LoginContainer isMobileSize={isMobile}>
            <form>
              <Row
                className="text-xl md:text-2xl py-6 border-b border-gray-300 h-full"
                align="center"
                justify="center"
              >
                Creá tu negocio en Ágora
              </Row>
              <Column className="px-10 lg:px-16 xl:px-[100px]">
                <Row className="py-6">
                  <SocialButton
                    social="google"
                    text="Continuar con Google"
                    onClick={() => authorize('google-oauth2')}
                  />
                </Row>
              </Column>
              <Row>
                <div className="flex items-center justify-center w-full">
                  <div className="flex-grow border-t border-gray-300"></div>
                  <span className="mx-4 text-[#212121]">O completá tus datos</span>
                  <div className="flex-grow border-t border-gray-300"></div>
                </div>
              </Row>
              <Column className="pt-8 md:pt-12 px-10 lg:px-16 xl:px-[100px]">
                <Grid className="w-full" columns={2} as="div" gap={12}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        onChange={onChange}
                        inputId="first_name"
                        placeholder="Nombre"
                        error={errors?.first_name?.message as string}
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        onChange={onChange}
                        inputId="last_name"
                        placeholder="Apellido"
                        error={errors?.last_name?.message as string}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Spacer spacing="12" />
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      inputId="email"
                      value={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const formattedValue = e.target.value.trim().toLowerCase();
                        onChange(formattedValue);
                      }}
                      placeholder="Email"
                      error={errors?.email?.message as string}
                      {...field}
                    />
                  )}
                />
                <Spacer spacing="12" />
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Input
                      onChange={onChange}
                      inputId="password"
                      placeholder="Contraseña"
                      variant="password"
                      error={errors?.password?.message as string}
                    />
                  )}
                />
                <Row className="py-6 text-sm">
                  <span className="text-[#868686]">
                    Te enviaremos notificaciones por e-mail, que podrás desactivar cuando quieras.
                  </span>
                </Row>
                <SubmitButton loading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
                  <PrimaryButtonContent>Registrarme</PrimaryButtonContent>
                </SubmitButton>
                <Row align="center" justify="center" gap={10} className="py-4">
                  <FontAwesomeIcon icon={faUser} color="#868686" />
                  <span className="text-[#868686]">
                    ¿Ya tenés cuenta?{' '}
                    <span
                      className="text-[#007aff] cursor-pointer underline"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Iniciar Sesión
                    </span>
                  </span>
                </Row>
              </Column>
            </form>
          </LoginContainer>
        </div>
      </Row>
    </>
  );
};

const ImageContainer = styled.div<{ isMobileSize: boolean; image: string }>`
  ${({ isMobileSize }) => isMobileSize && `display: none;`}
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  width: 500px;
  height: 500px;
`;

const LoginContainer = styled.div<{ isMobileSize: boolean }>`
  width: 100%;
  ${({ isMobileSize }) =>
    isMobileSize
      ? css`
          height: 100%;
          margin: 0;
        `
      : css`
          margin: 0px;
        `}
`;

const Spacer = styled.div<{ spacing: string }>`
  margin-top: ${({ spacing }) => `${spacing}px`};
`;

const PrimaryButtonContent = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.white};
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;
