import { useAuth } from '@/contexts';
import { useClient } from './useClient';
import { ErrorI } from '@/types/cyclone/requests';
import { useMutation } from 'react-query';

const useLog = () => {
  const { client } = useClient();
  const { session } = useAuth();

  const mutation = useMutation<any, ErrorI, { message: string }>((params) =>
    client<any>(`slack`, 'POST', {
      isAuthRequired: true,
      data: params
    })
  );

  const logAndNotify = (message: any) => {
    if (import.meta.env.VITE_NODE_ENV == 'production') {
      mutation.mutate({
        message: `${session?.vendor?.username} (${session?.email}) tuvo un error - ${JSON.stringify(message)}`
      });
    } else {
      console.log(message);
    }
  };

  return { logAndNotify };
};

export default useLog;
