import { Column } from '@/components';
import { scrollBarTW } from '@/components/App';
import { useAuth } from '@/contexts';
import { ServiceI } from '@/types/cyclone/models';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

interface ListVenuesPropsI {
  nextStep: () => void;
  setVenueIdSelected: Dispatch<SetStateAction<string | undefined>>;
  service: ServiceI;
}

export const ListVenues: React.FC<ListVenuesPropsI> = ({ service, setVenueIdSelected, nextStep }) => {
  const { session } = useAuth();

  const venues = service.is_on_demand
    ? session?.vendor?.venue?.vendors?.filter((v) =>
        service.multi_vendors?.some((mv) => mv.vendor_id === v.id)
      )
    : session?.vendor?.venue?.vendors?.filter((v) => v.id === service.vendor_id);

  if (session?.is_venue && venues?.length === 0)
    return (
      <Column className="pt-4">
        Por favor, asigna un staff a este servicio para poder ingresar una reserva
        <Link to="/equipo" className="pt-2 underline cursor-pointer">
          Asignar staff
        </Link>
      </Column>
    );

  return (
    <Column className={`min-h-96 max-h-96 overflow-y-auto ${scrollBarTW}`}>
      {venues?.map((venue, i) => {
        return (
          <Column
            key={i}
            gap={4}
            className="w-full p-4 first:border-t border-b hover:bg-[#E9E9E9] transition-colors duration-200 cursor-pointer"
            onClick={() => {
              setVenueIdSelected(venue.id.toString());
              nextStep();
            }}
          >
            <div className="flex items-center w-full h-full">
              <p className="text-left text-xl font-bold w-full leading-tight">{`${venue.user.first_name} ${venue.user.last_name}`}</p>
            </div>
          </Column>
        );
      })}
    </Column>
  );
};
